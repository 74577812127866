import * as React from 'react'
import { useParams, useSubmit } from 'react-router-dom'
import { Space } from 'antd'
import type { ModalProps } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import { useDrop } from 'hooks/dropzone'
import { idToIri } from 'services/apiPlatform'
import Modal from 'components/UI/Modal'
import { DOCUMENTS_ALLOWED_TYPES, DOCUMENTS_MAX_SIZE as maxSize } from 'constants/config'

export default function UploadDocumentModal(props: ModalProps) {
  const submit = useSubmit()
  const { lang, id } = useParams()
  const { i18n } = useI18n()

  const { onCancel } = props

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return
    const [file] = acceptedFiles

    const data = new FormData()
    data.append('file', file)
    data.append('type', 'user')
    data.append('user', idToIri('users', Number(id)))

    submit(data, {
      method: 'post',
      action: `/${lang}/dashboard/${id}`,
      encType: 'multipart/form-data',
    })

    // @ts-ignore
    onCancel()
  }, [lang, onCancel, submit, id])

  const { getRootProps, getInputProps } = useDrop({
    noClick: true,
    onDrop,
    accept: DOCUMENTS_ALLOWED_TYPES,
    maxSize,
  })
  return (
    <Modal
      title={i18n('dashboard.documents-upload-modal.title')}
      width="500px"
      footer={false}
      {...props}
    >
      <label className="w-full flex justify-center items-center pt-8 pb-4" {...getRootProps()}>
        <Space
          className="px-3 py-1 rounded-lg cursor-pointer border-2 border-dotted border-gray-300"
        >
          <UploadOutlined />
          {i18n('session.participants.upload-document')}
        </Space>
        <input {...getInputProps()} />
      </label>
    </Modal>
  )
}
