import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useI18n } from 'context/I18nProvider'
import useSWR from 'hooks/swr'
import Spin from 'components/UI/Spin'
import { API_CERTIFICATE_WISHES } from 'constants/api-v2'
import { CertificateWishType, STATUS_LABEL } from 'constants/accreditations'
import {
  ACCOMPLISHED_STATUS,
  ALLOWED_STATUS,
  CANCELED_STATUS,
  DISALLOWED_CONFIRMED_STATUS,
  DISALLOWED_STATUS,
  INITIED_STATUS,
  PENDING_CONFIRMED_STATUS,
  PENDING_STATUS,
  REGISTERED_STATUS,
  TO_REVIEW_STATUS,
  UNACCOMPLISHED_STATUS,
} from 'constants/trainingWishStatus'

interface Entry {
  accreditationName: string
  acquired: boolean
  endValidityDate: string
  status: string
}

export function Accreditations({ boardType } : {boardType: CertificateWishType}) {
  const { id } = useParams()
  const { i18n } = useI18n()
  const statusLabel = (status: string) => STATUS_LABEL.find(s => s.id === status) || { color: '', label: '-' }

  const acquiredText = (acquired: boolean) => acquired ? i18n('label.acquired') : i18n('label.not.acquired')

  const columns: ColumnsType<Entry> = [
    {
      dataIndex: CertificateWishType.ACCREDITATION === boardType ? 'accreditationName' : 'certificateName',
      render: (text, record) => {
        const { endValidityDate } = record
        const date = !!endValidityDate ? dayjs(record.endValidityDate).format('DD/MM/YYYY') : '−'
        return (
          <Space.Compact direction="vertical">
            <div className="text-[--primary] font-bold">{text}</div>
            <div className="text-sm text-gray-700">
              {`${i18n('label.accreditation.enddate')} : ${date}`}
            </div>
          </Space.Compact>
        )
      },
    }, {
      dataIndex: 'acquired',
      render: value => {
        const color = !!value ? 'text-[--primary]' : 'text-red-500'
        return (
          <div className={`text-center font-bold ${color}`}>{acquiredText(value)}</div>
        )
      },
    }, {
      dataIndex: 'status',
      render: value => {
        const { color, label } = statusLabel(value)
        return (
          <div className="text-center font-bold" style={{ color }}>
            {label ? i18n(label) : value}
          </div>
        )
      },
    },
  ]

  const ACCREDITATIONS_STATUSES = [
    ...[ALLOWED_STATUS, DISALLOWED_STATUS],
    ...[INITIED_STATUS, PENDING_STATUS],
    ...[TO_REVIEW_STATUS],
    ...[REGISTERED_STATUS],
    ...[ACCOMPLISHED_STATUS, UNACCOMPLISHED_STATUS],
    ...[CANCELED_STATUS, DISALLOWED_CONFIRMED_STATUS, PENDING_CONFIRMED_STATUS],
  ]

  let url = API_CERTIFICATE_WISHES
  url += '?groups[]=certificate-wishes:board'
  url += ACCREDITATIONS_STATUSES.map(s => `&status[]=${s}`).join('')
  url += `&user[]=${id}&itemsPerPage=3&order[id]=desc`
  url += boardType === CertificateWishType.ACCREDITATION
    ? '&exclude[certificate.accreditation.slug]=renewable_training'
    : '&certificate.accreditation.slug=renewable_training'
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
  )
}
