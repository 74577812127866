import * as React from 'react'
import { Badge } from 'antd'
import { BellOutlined } from '@ant-design/icons'

interface NotificationIconProps {
  count: number
  badgeClassName?: string
  className?: string
}

function NotificationIcon({ count, badgeClassName, className }: NotificationIconProps) {
  return (
    <Badge count={count} size="small" className={badgeClassName}>
      <BellOutlined className={className} />
    </Badge>
  )
}

export default NotificationIcon
