import * as React from 'react'
import { useI18n } from 'context/I18nProvider'
import AsyncSelect from 'components/UI/AsyncSelect'

export interface BaseAsyncSelectProps {
  name: string
  url: string
  label: string
  className?: string
  mapper?: (opt: any) => object
  styles?: {
    option: (provided: any, state: any) => any
  } | {
    [key: string]: (styles: any) => any // utils/select
  }
  apiPlatform?: boolean
  urlParams?: string
  value?: any
}

export default function BaseAsyncSelect({
  name,
  url,
  urlParams = '',
  label,
  className = '',
  value,
  ...props
}: BaseAsyncSelectProps) {
  const { i18n } = useI18n()

  const handleRawValue = (v: any) => {
    if (v === null) return null
    if (v === undefined) return undefined

    return typeof v === 'object'
      ? { label: v.label, value: v.value }
      : { label: v, value: v }
  }

  const rawValueMapper = (v: any) => Array.isArray(v)
    ? v.map(handleRawValue)
    : handleRawValue(v)

  return (
    <div className={className}>
      <AsyncSelect
        id={name}
        placeholder={i18n(label)}
        url={`${url}${urlParams}`}
        isMulti
        {...props}
        value={rawValueMapper(value)}
      />
    </div>
  )
}
