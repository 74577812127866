import * as React from 'react'
import { useI18n } from 'context/I18nProvider'
import { iriToId } from 'services/apiPlatform'

export default function useSelect() {
  const { i18n, i18name } = useI18n()

  const mapper = React.useCallback(obj => {
    if (!obj) return null
    return ({ label: i18name(obj), value: obj.id })
  }, [i18name])

  const userMapper = React.useCallback(obj => {
    if (!obj) return null
    return ({ ...obj, label: `${obj.firstName} ${obj.lastName}`, value: obj.id })
  }, [])

  const iriMapper = React.useCallback(obj => {
    if (!obj) return null
    return ({ ...obj, label: i18name(obj), value: iriToId(obj['@id']) })
  }, [i18name])

  const asyncProps = (props = {}) => ({
    loadingMessage: () => i18n('global_searching'),
    noOptionsMessage: () => i18n('global_no_options'),
    isClearable: true,
    ...props,
  })

  return {
    asyncProps,
    mapper,
    iriMapper,
    userMapper,
  }
}
