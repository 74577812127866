import React from 'react'
import { Route } from 'react-router-dom'
import Routes from 'components/RoutesWithErrorElement'
import Section from 'components/UI/Section'
import Home from './components/Home'
import SubFamily from './components/SubFamily'
import SkillTypes from './components/SkillTypes'
import Graph from './Graph'
import Header from './Header'
import { HeaderProvider } from './Header/context'
import './styles.scss'

function Matrix() {
  return (
    <HeaderProvider>
      <Section className="matrix">
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path="subfamily" element={<SubFamily />} />
          <Route path="skilltypes" element={<SkillTypes />} />
          <Route path="graph" element={<Graph />} />
        </Routes>
      </Section>
    </HeaderProvider>
  )
}

export default Matrix
