/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'hooks/swr'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import Modal from 'components/UI/Modal'
import Spinner from 'components/Spinner'
import { API_URL_TRAINING_WISH_DETAILS } from 'constants/api'
import { PROFILE_WISH_DETAILS } from 'constants/profile'
import DocumentWishTag from 'components/UI/DocumentWishTag'
import Timeline from './Timeline'
import './styles.scss'
const Row = ({ label, data }) => (
  <>
    <div className="description">{`${label}:`}</div>
    <div className="data">{data || '–'}</div>
  </>
)

Row.propTypes = {
  label: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

function Details({ id, onClose }) {
  const { i18n, i18name } = useI18n()
  const { profile, hasPermission } = useProfile()
  const canViewTimeline = hasPermission('TRAINING_WISH_CAN_VIEW_TIMELINE')
  const { data: wish, isValidating } = useSWR(API_URL_TRAINING_WISH_DETAILS.replace('ID', id))
  const provision = wish?.manager && !wish?.user
  const training = wish && `${i18name(wish.training)} (${wish.training.code})`
  const admin = profile.trainingWishDetails === PROFILE_WISH_DETAILS.ADMIN

  const mapDispositives = arr => (
    arr.map(item => i18name(item.plan))
      .reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])
      .join(', ')
  )

  const type = !!wish?.trainingWishPathwayId
    ? i18n('label.request_pathway')
    : wish?.training?.fictive
      ? i18n('label.outside_catalog')
      : i18n('label.request_catalog')

  const waiting = isValidating || !wish

  return (
    <Modal
      title={`${i18n('label.training_wish_details_view')} N°${id}`}
      onCancel={onClose}
      width="80%"
      footer={null}
    >
      {waiting && <Spinner />}
      {!waiting && (
        <div className="details-layout">
          <div className="details-modal">
            <div className="block">
              {
                provision
                  ? <Row label={i18n('modal.detail.managerprovision')} data={wish.manager && wish.manager.full_name} />
                  : <Row label={i18n('label.trainee')} data={wish.user && wish.user.full_name} />
              }
              <Row
                label={i18n('label.quantity')}
                data={wish.current_provisions === null ? 1 : wish.current_provisions}
              />
            </div>
            <div className="block">
              <Row label={i18n('label.type')} data={type} />
              <Row label={i18n('label.vintage')} data={wish.vintage ? i18name(wish.vintage) : '-'} />
              <Row label={i18n('label.attribut')} data={i18n(wish.wish_period_type)} />
              {hasPermission('TRAINING_WISH_CAN_VIEW_PRIORITY') && (
                <Row label={i18n('label.priority')} data={i18name(wish.priority)} />
              )}
              <Row label={i18n('label.date')} data={wish.creation_date} />
              <Row label={i18n('label.request_expressed_by')} data={wish.creator && wish.creator.full_name} />
            </div>
            <div className="block">
              <Row label={i18n('label.category')} data={i18name(wish.training.category)} />
              <Row label={i18n('label.theme')} data={i18name(wish.training.theme)} />
              <Row label={i18n('label.description')} data={training} />
              { wish?.trainingWishPathwayId && (
                <>
                  <Row label={i18n('label.linked.pathway')} data={wish?.trainingPathwayName || '-'} />
                  <Row label={i18n('label.requested.linked.pathway')} data={wish?.trainingWishPathwayId} />
                </>
              )}
              <Row label={i18n('label.duration')} data={`${wish.duration} ${i18n('label.hours')}`} />
              <Row label={i18n('label.cost')} data={`${wish.cost} €`} />
            </div>
            <div className="block">
              <Row label={i18n('label.objective')} data={wish.why} />
              <Row label={i18n('label.comment')} data={wish.comment} />
            </div>
            <div className="block">
              <Row label={i18n('label.status')} data={i18n(wish.status)} />
              <Row
                label={i18n('label.absence_reason')}
                data={wish.attending && wish.attending.absence_reason && i18name(wish.attending.absence_reason)}
              />
              <Row label={i18n('label.delete_reason')} data={wish.delete_reason && i18name(wish.delete_reason)} />
              <Row label={i18n('label.delete_reason_comment')} data={wish.delete_reason_comment} />
              <Row label={i18n('trainingWishCancelReason')} data={i18name(wish.motif_cancel)} />
              <Row label={i18n('trainingWishUnCancelComment')} data={wish.comment_uncancel || '-'} />
              <Row label={i18n('label.refusal')} data={i18name(wish.motif_refusal)} />
              <Row label={i18n('label.valdate_to_the_plan')} data={i18n(wish.validate_label)} />
            </div>
            {
              admin
                ? (
                  <div className="block">
                    <Row label={i18n('label.project')} data={i18name(wish.project) || '-'} />
                    <Row label={i18n('label.motif')} data={i18name(wish.training_wish_reason) || '-'} />
                    <Row label={i18n('label.type')} data={i18name(wish.training_wish_type) || '-'} />
                    <Row label={i18n('label.orientation')} data={i18name(wish.orientation) || '-'} />
                    <Row label={i18n('label.perimeter')} data={i18name(wish.training_wish_perimeter) || '-'} />
                    <Row label={i18n('label.budget')} data={i18name(wish.budget) || '-'} />
                    <Row
                      label={i18n('label.plan')}
                      data={wish.training_wish_plans ? mapDispositives(wish.training_wish_plans) : 'n/a'}
                    />
                  </div>
                )
                : null
            }
          </div>
          {
            canViewTimeline && (
              <div className="timeline-layout">
                <Timeline
                  id={id}
                  filter={({ event }) => !['mail', 'mail_collaborator', 'mail_manager'].includes(event)}
                />
                <hr className="my-3"></hr>
                <div>
                  <div className="description my-3">{i18n('documents.title-join')}</div>
                  {wish.documents?.map((el, index) => (
                    <DocumentWishTag
                      readonly
                      key={index}
                      {...el}
                    />
                  ))}
                </div>
              </div>
            )
          }
        </div>
      )}

    </Modal>
  )
}

Details.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Details
