import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'context/I18nProvider'
import Button from 'components/UI/Button'
import Input from 'components/UI/Input'
import { useNotification } from 'context/NotificationProvider'
import { useQuery } from 'context/QueryProvider'
import { API_URL_LOGIN_FORGOT_PASSWORD } from 'constants/api'
import ArrowBack from './ArrowBack'
import Form, { H1, H2 } from './Decorators'

function ForgottenPassword() {
  const { i18n } = useI18n()
  const { notifyError } = useNotification()
  const { client } = useQuery()

  const [username, setUsername] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      await client.post(API_URL_LOGIN_FORGOT_PASSWORD, { username })
      navigate('confirmation')
    } catch {
      notifyError(i18n('login-page.login.fail'))
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ArrowBack />
      <H1>{i18n('login-page.forgotten.password')}</H1>
      <H2>{i18n('login-page.forgotten.password.instructions')}</H2>
      <Input
        className="h-fit"
        placeholder={i18n('login-page.username.label')}
        name="user"
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <Button className="h-9" onClick={handleSubmit} disabled={!username?.length}>
        {i18n('login-page.forgotten.password.receive.email')}
      </Button>
    </Form>
  )
}

export default ForgottenPassword
