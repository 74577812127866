import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'context/I18nProvider'
import Button from 'components/UI/Button'
import { useProfile } from 'context/ProfileProvider'

function OutsideCatalogLink() {
  const navigate = useNavigate()
  const { i18n } = useI18n()
  const { hasPermission } = useProfile()
  const canRequest = hasPermission('CATALOG_CAN_REQUEST') && hasPermission('CATALOG_CREATE_OUT_OF_CATALOG')

  if (!canRequest) return null

  return (
    <div className="absolute -top-16 right-0 flex items-center">
      <span className="text-white text-sm mr-4">
        {i18n('question.you_dont_find_your_training')}
      </span>

      <Button onClick={() => navigate('free-wish')}>
        {i18n('action.free_wish')}
      </Button>
    </div>
  )
}

export default OutsideCatalogLink
