import QueryClient from 'services/query'
import { API_DOCUMENTS } from 'constants/api-v2'

interface LoaderParams {
  params: {
    id?: string
  }
  request: Request
}

// Dashboard documents
export const loadUserDocuments = (client: QueryClient) => (
  async ({ params, request }: LoaderParams) => {
    const { id } = params
    const page = new URL(request.url).searchParams.get('page')

    let documents = []
    let error = null

    try {
      documents = await client.get(`${API_DOCUMENTS}?user=${id}&page=${page || 1}&itemsPerPage=10&order[id]=desc`)
    } catch (e: any | unknown) {
      error = (e.response)
    }
    return { documents, error }
  }
)

// Use to download a specific document
export const loadDocument = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let document = null
    let error = null

    try {
      document = await client.get(`${API_DOCUMENTS}/${id}`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
      error = (e.response.message)
    }
    return { document, error }
  }
)
