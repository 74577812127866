// import { redirect } from 'react-router-dom'
import { API_DOCUMENTS } from 'constants/api-v2'
import QueryClient from 'services/query'

export const createDocument = (client: QueryClient) => (
  async ({ request }: { request: Request }) => {
    const formData = await request.formData()

    try {
      await client.post(API_DOCUMENTS, formData)
    } catch (e: Error | any) {
      return { error: e }
    }

    return { error: null, status: 'success' }
  }
)
