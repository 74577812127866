/**
 * `menuPosition="fixed"` is needed so the select menu appears outside the bounds of our modal (if needed)
 * Warning : using these props outside modal may cause this bug https://github.com/JedWatson/react-select/issues/3734
 * and this one : https://gitlab.com/eleo-rh/training/-/issues/3525
 */
export const selectModalProps = {
  menuPortaltarget: document.body,
  styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
  menuPosition: 'fixed',
  menuPlacement: 'bottom',
}

export const menuZIndex = {
  menu: styles => ({
    ...styles,
    zIndex: 9999,
  }),
}

// @see https://gitlab.com/eleo-rh/training/-/issues/7505
export const draftOrganizationsStyles = {
  option: (provided, state) => ({
    ...provided,
    fontStyle: state.data.draft ? 'italic' : 'normal',
  }),
}
