import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { CalendarFilled, ClockCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import dayjs from 'dayjs'
import type { TrainingWish, Translations } from 'shared/types'
import { useI18n } from 'context/I18nProvider'
import useSWR from 'hooks/swr'
import { capitalize } from 'services/string'
import Spin from 'components/UI/Spin'
import TrainingIcon from 'components/UI/Icons/TrainingIcon'
import { API_TRAINING_WISHES } from 'constants/api-v2'
import { useProfile } from 'context/ProfileProvider'
import {
  ALLOWED_STATUS,
  DISALLOWED_STATUS,
  INITIED_STATUS,
  PENDING_STATUS,
  REGISTERED_STATUS,
  TO_REVIEW_STATUS,
} from 'constants/trainingWishStatus'
import { TopButton } from './Buttons'
import { Separator, TopCard, TopContent } from './Content'

interface PendingEntry {
  date: string
  hasSpots: boolean
  training: Translations
}
interface SessionEntry {
  date: string
  done: boolean
  training: Translations
}

function Wishes() {
  const { hasPermission } = useProfile()
  const { id } = useParams()
  const { i18n, i18name, locale } = useI18n()
  const navigate = useNavigate()

  const columns: ColumnsType<TrainingWish> = [
    {
      dataIndex: 'id',
      render: (_: string, record: any) => (
        <Space.Compact direction="vertical">
          <div className="text-[--primary] font-bold line-clamp-1">{capitalize(i18name(record.training))}</div>
          <div className="text-sm text-gray-700">
            {`Demandée le : ${dayjs(record.creationDate).format('DD/MM/YYYY')}`}
          </div>
        </Space.Compact>
      ),
    }, {
      dataIndex: 'duration',
      className: '!px-2',
      width: 100,
      render: (text: string) => <Space><ClockCircleOutlined />{!!text ? `${text} h` : '−'}</Space>,
    },
  ]

  const WISHES_STATUSES = [
    ...[ALLOWED_STATUS, DISALLOWED_STATUS],
    ...[INITIED_STATUS, PENDING_STATUS],
  ]

  let url = API_TRAINING_WISHES
  url += '?groups[]=training-wishes:list:needs'
  url += WISHES_STATUSES.map(s => `&status[]=${s}`).join('')
  url += `&user[]=${id}&itemsPerPage=3&order[id]=desc`
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <TopCard
      className="w-[30%]"
      title={i18n('dashboard.needs.title')}
      extra={
        hasPermission('TRAINING_WISH_CAN_VIEW') && (
          <TopButton onClick={() => navigate(`/${locale}/needs-summary?user[]=${id}`)}>
            {i18n('label.view')}
          </TopButton>
        )
      }
    >
      <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
    </TopCard>
  )
}

function Pending() {
  const { hasPermission } = useProfile()
  const { id } = useParams()
  const navigate = useNavigate()
  const { i18n, i18name, locale } = useI18n()

  const columns: ColumnsType<PendingEntry> = [
    {
      dataIndex: 'hasSpots',
      width: 3,
      className: '!p-4 !pr-0',
      render: (text: boolean) => (
        text && <div className="bg-[--primary] w-[3px] h-[34px]" />
      ),
    }, {
      dataIndex: 'title',
      render: (_: string, record: any) => (
        <Space.Compact direction="vertical">
          <div className="text-[--primary] font-bold line-clamp-1">{capitalize(i18name(record.training))}</div>
          <div className="text-sm text-gray-700">
            {!!record.availableSessions ? (
              <Space>
                <ExclamationCircleFilled className="text-orange-600" />
                <div className="text-gray-800 font-bold uppercase cursor-pointer">
                  {i18n('can_register')}
                </div>
              </Space>
            ) : (
              <div className="text-[--secondary] italic">{i18n('no_spots_left')}</div>
            )}
          </div>
        </Space.Compact>
      ),
    }, {
      dataIndex: 'duration',
      className: '!px-2',
      width: 100,
      render: (text: string) => <Space><ClockCircleOutlined />{!!text ? `${text} h` : '−'}</Space>,
    },
  ]

  const PENDING_STATUSES = [
    ...[TO_REVIEW_STATUS],
  ]

  let url = API_TRAINING_WISHES
  url += '?groups[]=training-wishes:list:training-plan:to-review'
  url += PENDING_STATUSES.map(s => `&status[]=${s}`).join('')
  url += `&user[]=${id}&itemsPerPage=3&order[id]=desc`
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <TopCard
      className="w-[30%]"
      title={i18n('dashboard.to_review.title')}
      extra={
        hasPermission('TRAINING_PLAN_CAN_VIEW') && (
          // eslint-disable-next-line max-len
          <TopButton onClick={() => navigate(`/${locale}/training-plan?user[]=${id}${PENDING_STATUSES.map(s => `&status[]=${s}`).join('')}`)}>
            {i18n('dashboard.training_wishes.to_review.button.view')}
          </TopButton>
        )
      }
    >
      <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
    </TopCard>
  )
}


function Session() {
  const { hasPermission } = useProfile()
  const { id } = useParams()
  const navigate = useNavigate()
  const { i18n, i18name, locale } = useI18n()

  const columns: ColumnsType<SessionEntry> = [
    {
      dataIndex: 'done',
      width: 3,
      className: '!p-4 !pr-0',
      render: (text: boolean) => (
        !!text && <div className="bg-[--primary] w-[3px] h-[34px]" />
      ),
    }, {
      dataIndex: 'title',
      render: (_: string, record: any) => (
        <Space.Compact direction="vertical">
          <div className="text-[--primary] font-bold line-clamp-1">{capitalize(i18name(record.training))}</div>
          <div className="text-sm text-gray-700">
            {/* @TODO Evaluation from API ? */}
            {!!record.done ? (
              <Space>
                <ExclamationCircleFilled className="text-orange-600" />
                <div className="text-gray-800 font-bold uppercase cursor-pointer">
                  {i18n('label.evaluate')}
                </div>
              </Space>
            ) : <div>&nbsp;</div>}
          </div>
        </Space.Compact>
      ),
    }, {
      dataIndex: ['session', 'dateStart'],
      className: '!px-2',
      width: 70,
      render: (text: string) => (
        <Space className="text-gray-700">
          <CalendarFilled />
          {dayjs(text).format('DD/MM/YYYY')}
        </Space>
      ),
    },
  ]

  const SESSION_STATUSES = [
    ...[REGISTERED_STATUS],
  ]

  let url = API_TRAINING_WISHES
  url += '?groups[]=training-wishes:list:training-plan:registered&groups[]=sessions-view'
  url += SESSION_STATUSES.map(s => `&status[]=${s}`).join('')
  url += `&user[]=${id}&itemsPerPage=3&order[id]=desc`
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <TopCard
      className="w-[30%]"
      title={i18n('dashboard.registered.title')}
      extra={
        hasPermission('TRAINING_PLAN_CAN_VIEW') && (
          // eslint-disable-next-line max-len
          <TopButton onClick={() => navigate(`/${locale}/training-plan?user[]=${id}${SESSION_STATUSES.map(s => `&status[]=${s}`).join('')}`)}>
            {i18n('dashboard.training_wishes.registered.button.view')}
          </TopButton>
        )
      }
    >
      <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
    </TopCard>
  )
}

export function Trainings() {
  const { i18n } = useI18n()

  return (
    <TopContent
      icon={<TrainingIcon />}
      title={i18n('dashboard.trainings.title')}
    >
      <div className="w-full flex justify-between">
        <Wishes />
        <Separator />
        <Pending />
        <Separator />
        <Session />
      </div>
    </TopContent>
  )
}
