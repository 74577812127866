import * as React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const dsn = 'https://999928a7aab347bd8c82352e13ada755@o1010585.ingest.sentry.io/5975212'
const excludedHosts = ['localhost', 'ariane', '127.0.0.1']
const hostname = window?.location?.host || 'localhost'
const matchesExcluded = excludedHosts.some(excluded => hostname.indexOf(excluded) >= 0)
const active = !matchesExcluded

export const initSentry = () => {
  if (active) {
    Sentry.init({
      dsn,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
      ],
      integrations: [new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      })],
      tracesSampleRate: 1.0,
    })
  }
}

export const sentryCreateBrowserRouter = active
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter
