const SPECIAL_CHARS = [...' !"”#$%&’\'()*+,-./:;<=>?@[\\]^_`{|}~']

export const validatePasswords = (password, passwordConfirm, complexity) => {
  const messages = []

  if (!complexity) {
    messages.push({ label: 'login-page.password.change.nonempty', success: !!password?.length })
    messages.push({
      label: 'login-page.password.change.equal',
      success: !!password?.length && password === passwordConfirm,
    })
  } else {
    messages.push({ label: 'login-page.password.change.length', success: password.length >= 8 })
    messages.push({
      label: 'login-page.password.change.equal',
      success: !!password?.length && password === passwordConfirm,
    })
    messages.push({ label: 'login-page.password.change.number', success: /.*[0-9]+.*/.test(password) })
    messages.push({ label: 'login-page.password.change.upperchar', success: /.*[A-Z]+.*/.test(password) })
    messages.push({ label: 'login-page.password.change.lowerchar', success: /.*[a-z]+.*/.test(password) })
    messages.push({
      label: 'login-page.password.change.special',
      success: SPECIAL_CHARS.some(c => password.indexOf(c) >= 0),
    })
  }

  return messages
}
