import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'context/I18nProvider'
import Button from 'components/UI/Button'
import ArrowBack from './ArrowBack'
import Form, { H1, H2 } from './Decorators'

function ForgottenConfirm() {
  const { i18n, locale } = useI18n()
  const navigate = useNavigate()
  const goBack = () => navigate(`/${locale}/login`)

  return (
    <Form onSubmit={goBack}>
      <ArrowBack />
      <H1>{i18n('login-page.forgotten.password')}</H1>
      <H2>{i18n('login-page.forgotten.password.confirmation')}</H2>
      <Button onClick={goBack}>{i18n('label.back')}</Button>
    </Form>
  )
}

export default ForgottenConfirm
