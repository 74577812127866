import type { Training } from 'shared/types'

export const getBackground = (training: Training): { url: string } | null => {
  if (training.backgroundDocument) {
    return training.backgroundDocument
  }

  if (training.theme?.backgroundDocument) {
    return training.theme.backgroundDocument
  }

  if (training.theme?.category.backgroundDocument) {
    return training.theme.category.backgroundDocument
  }

  return null
}
