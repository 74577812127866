export const getSearchFilters = () => {
  const filters: any = {};

  (new URLSearchParams(window.location.search)).forEach((value, key) => {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      if (Array.isArray(filters[key])) {
        filters[key].push(value)
      } else {
        filters[key] = [filters[key], value]
      }
    } else {
      filters[key] = value
    }
  })

  return filters
}
