import React from 'react'
import ReactDOM from 'react-dom'
import Auth from 'services/auth'
import QueryClient from 'services/query'
import AuthProvider from 'context/AuthProvider'
import I18nProvider from 'context/I18nProvider'
import QueryProvider from 'context/QueryProvider'
import { getDefaultLocale } from 'utils/locale'
import App from './app'
import './style.css'

const locale = getDefaultLocale()
const queryClient = new QueryClient(locale)
const authService = new Auth({ queryClient })

if (process.env.NODE_ENV === 'development' && process.env.SYMFONY_TOOLBAR) {
  // We use dynamic require here in order to completely remove this code in production build
  // eslint-disable-next-line global-require
  require('./toolbar')
}

ReactDOM.render(
  <AuthProvider auth={authService} client={queryClient}>
    <QueryProvider client={queryClient}>
      <I18nProvider>
        <App client={queryClient} />
      </I18nProvider>
    </QueryProvider>
  </AuthProvider>,
  document.getElementById('root'),
)
