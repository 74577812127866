import * as React from 'react'
import { useI18n } from 'context/I18nProvider'
import { getId } from 'services/apiPlatform'
import BaseAsyncSelect from 'components/Filters/BaseAsyncSelect'
import { API_TRAINING_THEMES } from 'constants/api-v2'

export default function Themes({ ...props }) {
  const { i18name } = useI18n()

  return (
    <BaseAsyncSelect
      name="theme"
      label="label.themes"
      url={API_TRAINING_THEMES}
      mapper={opt => ({ ...opt, label: i18name(opt), value: getId(opt) })}
      apiPlatform
      {...props}
    />
  )
}
