import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { useI18n } from 'context/I18nProvider'
import { useQuery } from 'context/QueryProvider'
import { useNotification } from 'context/NotificationProvider'
import Button from 'components/UI/Button'
import Input from 'components/UI/Input'
import Spinner from 'components/Spinner'
import { API_URL_LOGIN_FORGOT_PASSWORD_VALIDATE, API_URL_LOGIN_UPDATE_PASSWORD } from 'constants/api'
import Form, { Block, Center, ErrorText, H1, H2 } from './Decorators'
import { validatePasswords } from './controller'

const useValidateToken = () => {
  const { client } = useQuery()
  const [complexity, setComplexity] = useState()
  const [error, setError] = useState()
  const [ready, setReady] = useState()

  const u = new URLSearchParams(document.location.search)
  const token = u.get('token')

  useEffect(() => {
    client.post(API_URL_LOGIN_FORGOT_PASSWORD_VALIDATE, { token }, { withToken: false })
      .then(resp => setComplexity(resp.complexity))
      .catch(setError)
      .finally(() => setReady(true))
  }, [client, token])
  return { complexity, error, token, ready }
}

function ResetPassword() {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { client } = useQuery()
  const { notifyError } = useNotification()

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const { complexity, token, error, ready } = useValidateToken()


  const validationMessages = validatePasswords(password, passwordConfirm, complexity)
  const valid = validationMessages.reduce((ok, m) => ok && m.success, true)

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      await client.post(API_URL_LOGIN_UPDATE_PASSWORD, { token, plainPassword: password }, { withToken: false })
      navigate('reset-password/confirmation')
    } catch (e) {
      notifyError(e)
    }
  }

  if (!ready) return <Spinner />

  if (error) {
    return (
      <Form>
        <H1>{i18n('login-page.forgotten.password')}</H1>
        <ErrorText>{i18n('login-page.password.reset.token')}</ErrorText>
      </Form>
    )
  }

  return (
    <Form onSubmit={handleSubmit} height="h-48">
      <H1>{i18n('login-page.forgotten.password')}</H1>
      <H2>{i18n('login-page.password.change.explanations')}</H2>
      <Input
        placeholder={i18n('login-page.password.label')}
        name="password"
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Input
        placeholder={i18n('login-page.password.confirm.label')}
        name="passwordConfirm"
        type="password"
        value={passwordConfirm}
        onChange={e => setPasswordConfirm(e.target.value)}
      />
      {
        validationMessages.map(m => (
          <Block>
            <div
              key={m.label}
              className={cx('flex items-center', { 'text-green-600': m.success, 'text-red-600': !m.success })}
            >
              {m.success ? <CheckOutlined /> : <CloseOutlined />}
              <span className="pl-2">{i18n(m.label)}</span>
            </div>
          </Block>
        ))
      }
      <Center>
        <Button onClick={handleSubmit} disabled={!valid}>{i18n('label.validate')}</Button>
      </Center>
    </Form>
  )
}

export default ResetPassword
