import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useSWR from 'hooks/swr'

import { useI18n } from 'context/I18nProvider'
import Spinner from 'components/Spinner'
import { API_URL_TRAINING_PLAN_HISTORY } from 'constants/api'

import { translateEvents } from './translator'
import './styles.scss'

function Timeline({ id, timestamp, filter }) {
  const { i18n } = useI18n()
  const { data, isValidating } = useSWR(API_URL_TRAINING_PLAN_HISTORY.replace('ID', id))

  const events = !isValidating && data ? translateEvents(data.filter(filter), i18n, timestamp) : []
  const hasEvents = !isValidating && events.length > 0

  return (
    <div className={cx('timeline-card', { empty: !hasEvents })}>
      { isValidating ? <Spinner />
        : hasEvents ? events.map(event => <TimeLineRow key={event.id} date={event.date} topic={event.topic} />)
          : i18n('timeline.noevents')}

    </div>
  )
}

const TimeLineRow = ({ date, topic }) => (
  <div className="timeline-row">
    <div className="drawing"><div></div><span></span></div>
    <div className="date">{date}</div>
    <div className="topic">{topic}</div>
  </div>
)

Timeline.defaultProps = {
  filter: events => events,
}

Timeline.propTypes = {
  id: PropTypes.number.isRequired,
  timestamp: PropTypes.bool,
  filter: PropTypes.func,
}

TimeLineRow.propTypes = {
  date: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
}

export default Timeline
