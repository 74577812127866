/* eslint-disable react/prop-types */
import React from 'react'
import { useI18n } from 'context/I18nProvider'
import ErrorPage from 'components/UI/ErrorPage'

export default function Page403() {
  const { i18n } = useI18n()

  return (
    <ErrorPage
      customError={{ statusText: i18n('page-403.text') }}
      title="403"
    />
  )
}
