/* eslint-disable react/prop-types */
import * as React from 'react'
import { useLocation } from 'react-router-dom'

const SidebarContext = React.createContext()

const BREAKPOINT = 1190

export default function SidebarProvider({ children }) {
  const location = useLocation()
  // Sidebar is manually open or not
  const [navOpen, setNavOpen] = React.useState(true)
  // Filter menu is open or closed
  const [filterOpen, setFilterOpen] = React.useState(false)
  // Sidebar is automatically collapsed or not depending on window width
  const [collapsed, setCollapsed] = React.useState(false)
  // When Sidebar is closed or collapsed, on mouse enter it will float back to its wide width
  const [floating, setFloating] = React.useState(false)
  // Save state of nav when opening filters to come back to it when closing
  // @see https://gitlab.com/eleo-rh/training/-/issues/8853
  const [navWasOpen, setNavWasOpen] = React.useState(false)
  // Back Button was pressed
  const [backBtn, setBackBtn] = React.useState(false)
  // Window width for collapsed state
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  // Page has filters
  const [hasFilters, setHasFilters] = React.useState(false)
  // Filters count in Sidebar
  const [filtersCount, setFiltersCount] = React.useState(0)

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Auto collapse Sidebar
  if (!!windowWidth && (windowWidth < BREAKPOINT) && !collapsed) setCollapsed(true)
  if (!!windowWidth && (windowWidth > BREAKPOINT) && !!collapsed) setCollapsed(false)

  React.useEffect(() => {
    window.onpopstate = () => setBackBtn(true)
  }, [])

  // Listen to route changes
  React.useEffect(() => {
    const pageWithFilters = document.getElementById('filters-root')?.children?.length === 1
    // When navigating from a page with filters, check if the new page also has filters
    // @see https://gitlab.com/eleo-rh/training/-/issues/8869
    if (filterOpen && !pageWithFilters) {
      setNavOpen(navWasOpen)
      setFilterOpen(false)
    }
    setHasFilters(pageWithFilters)
  }, [collapsed, filterOpen, location, navWasOpen])

  React.useEffect(() => {
    // Special case when reaching a new route via the browser back button, close the filters menu and open nav
    // @see https://gitlab.com/eleo-rh/training/-/issues/8869
    if (!!backBtn) {
      if (filterOpen) {
        setFilterOpen(false)
        if (!collapsed) setNavOpen(true)
      }
    }
  }, [backBtn]) // eslint-disable-line

  // Overall wide state of sidebar
  const wide = React.useMemo(() => (
    navOpen
      ? (!!collapsed ? !!floating : true)
      : !!floating
  ), [collapsed, floating, navOpen])

  const toggleNav = React.useCallback(() => {
    if (collapsed) {
      setFloating(!floating)
    } else {
      const newState = !navOpen
      setNavOpen(newState)
      if (newState && filterOpen) setFilterOpen(false)
    }
  }, [collapsed, filterOpen, floating, navOpen])

  const toggleFilter = React.useCallback(() => {
    const newState = !filterOpen
    if (!!newState) {
      setNavOpen(false)
      // When opening filters, save nav state
      setNavWasOpen(navOpen)
    } else if (navWasOpen) {
      setNavOpen(true)
    }
    setFilterOpen(newState)
  }, [filterOpen, navOpen, navWasOpen])

  const memoizedValue = React.useMemo(() => ({
    navOpen,
    filterOpen,
    toggleFilter,
    toggleNav,
    collapsed,
    setCollapsed,
    floating,
    setFloating,
    wide,
    hasFilters,
    filtersCount,
    setFiltersCount,
  }), [
    collapsed,
    filterOpen,
    filtersCount,
    floating,
    hasFilters,
    navOpen,
    setCollapsed,
    setFiltersCount,
    setFloating,
    toggleFilter,
    toggleNav,
    wide,
  ])

  return (
    <SidebarContext.Provider value={memoizedValue}>
      {children}
    </SidebarContext.Provider>
  )
}

export function useSidebar() {
  return React.useContext(SidebarContext)
}
