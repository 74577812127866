import React from 'react'
import PropTypes from 'prop-types'
import { useModal } from 'react-modal-hook'
import { EditOutlined } from '@ant-design/icons'
import useSWR from 'hooks/swr'
import { useI18n } from 'context/I18nProvider'
import UpdateManager from 'components/TrainingWish/UpdateManager'
import UpdateTraining from 'components/TrainingWish/UpdateTraining'
import { API_TRAINING_WISHES_ACTIONS } from 'constants/api-v2'

function Header({ trainingWish, onUpdate }) {
  const { i18n, i18name } = useI18n()
  const { data: permissions } = useSWR(API_TRAINING_WISHES_ACTIONS.replace(':id', trainingWish.id))

  // eslint-disable-next-line camelcase
  const manager = `${trainingWish.manager.last_name} ${trainingWish.manager.first_name}`.toLowerCase()
  const canChangeTraining = permissions?.updateTraining
  const canChangeManager = permissions?.assign

  const trainingName = i18name(trainingWish?.training)
  const [showUpdateTraining, hideUpdateTraining] = useModal(() => (
    <UpdateTraining
      doneAction={onUpdate}
      onClose={hideUpdateTraining}
      id={trainingWish.id}
      title={trainingName}
      isFictive={trainingWish.training.fictive}
    />
  ), [trainingWish.id])

  const [showUpdateManager, hideUpdateManager] = useModal(() => (
    <UpdateManager doneAction={onUpdate} onClose={hideUpdateManager} id={trainingWish.id} />
  ))

  return (
    <div className="text-xl w-full flex justify-between">
      <div className="flex items-center">
        {`${i18n('page_title.manage_provision')} - ${trainingName}`}
        {canChangeTraining && <EditOutlined onClick={showUpdateTraining} className="text-[--primary] ml-2" />}
      </div>
      <div className="flex items-center">
        {`${i18n('manager')} : ${manager}`}
        {canChangeManager && <EditOutlined onClick={showUpdateManager} className="text-[--primary] ml-2" />}
      </div>
    </div>
  )
}

Header.propTypes = {
  trainingWish: PropTypes.shape({
    id: PropTypes.number.isRequired,
    training: PropTypes.object.isRequired,
    manager: PropTypes.shape({
      last_name: PropTypes.string,
      first_name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  onUpdate: PropTypes.func,
}

export default Header
