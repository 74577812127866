import * as React from 'react'
import { API_ORGANIZATIONS } from 'constants/api-v2'
import BaseAsyncSelect from 'components/Filters/BaseAsyncSelect'

interface OrganizationsValue {
  id: number
  name: string
  draft?: boolean
  company?: boolean
}

interface OrganizationsProps {
  draft?: boolean
  company?: boolean
  trainingOrganization?: boolean
}

export default function Organizations({ draft, company, trainingOrganization, ...props }: OrganizationsProps & any) {
  /* eslint-disable-next-line max-len */
  const url = `${API_ORGANIZATIONS}?${typeof trainingOrganization !== 'undefined' ? `&trainingOrganization=${trainingOrganization}` : ''}${typeof company !== 'undefined' ? `&company=${company}` : ''}${typeof draft !== 'undefined' ? `&draft=${draft}` : ''}`

  return (
    <BaseAsyncSelect
      name="organizations"
      label="label.training_organization"
      url={url}
      mapper={(o: OrganizationsValue) => ({ label: o.name, value: o.id, draft: o.draft })}
      apiPlatform
      {...props}
    />
  )
}
