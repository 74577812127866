import * as React from 'react'
import { API_URL_ANIMATION_LOCALES } from 'constants/api'
import BaseAsyncSelect from 'components/Filters/BaseAsyncSelect'

export default function AnimationLocales({ ...props }) {
  return (
    <BaseAsyncSelect
      name="language"
      label="label.language"
      url={API_URL_ANIMATION_LOCALES}
      {...props}
    />
  )
}
