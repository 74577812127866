import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfigProvider, Tabs } from 'antd'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import { useTitle } from 'hooks/title'
import Accreditation from 'containers/Catalog/Accreditation'
import Matrix from 'containers/Catalog/Matrix'
import Training from 'containers/Catalog/Training'

function Label({ txt }: { txt: string }) {
  const { i18n } = useI18n()
  // @see https://gitlab.com/eleo-rh/training/-/issues/8527
  return (
    <div className="text-white text-sm font-bold uppercase mt-2 mx-10">
      {i18n(txt)}
    </div>
  )
}

const TABS = [{
  key: 'training',
  label: <Label txt="label.training.search" />,
  children: <Training />,
  permission: 'CATALOG_CAN_SEE_CATALOG',
}, {
  key: 'accreditation',
  label: <Label txt="menu.admin_accreditations" />,
  children: <Accreditation />,
  permission: 'ACCREDITATION_CAN_SEE_CATALOG',
}, {
  key: 'matrix',
  children: <Matrix />,
  label: <Label txt="page_title.jobs_references" />,
  permission: 'MATRIX_CAN_SEE_MATRIX',
}]

function CatalogNav() {
  const { lang, tab } = useParams()
  const navigate = useNavigate()

  const { hasPermission } = useProfile()
  const { i18n } = useI18n()
  useTitle(i18n('menu.catalog'))

  const tabs = TABS.filter(t => hasPermission(t.permission))

  const handleChange = (key: string) => navigate(`/${lang}/catalog/${key}`)

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            itemColor: 'white',
            itemActiveColor: 'white',
            itemHoverColor: 'white',
            itemSelectedColor: 'white',
            inkBarColor: 'white',
          },
        },
      }}
    >
      <Tabs activeKey={tab} items={tabs} onChange={handleChange} />
    </ConfigProvider>
  )
}

export default CatalogNav
