import * as React from 'react'
import { useI18n } from 'context/I18nProvider'
import { styledIcon } from 'services/icon'
import MenuLink from './MenuLink'
import type { MenuItem } from '../types'

interface MenuProps {
  items: MenuItem[]
}

export default function Menu({ items, ...props }: MenuProps) {
  const { i18n } = useI18n()

  return (
    <ul className="p-0 m-0" {...props}>
      {items.map((item, i) => (
        <li key={`${i}-${item.name}`}>
          <MenuLink
            to={item.href || ''}
            className="leading-4 py-3 mb-0.5 relative flex items-center rounded hover:bg-gray-200"
          >
            <span className="text-[--secondaryLight] w-6 mx-2 text-center">
              {styledIcon(item.icon, { fontSize: '1.2rem' })}
            </span>
            <span className="text-[#444] font-bold">{i18n(item.name)}</span>
          </MenuLink>
        </li>
      ))}
    </ul>
  )
}
