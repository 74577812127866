import QueryClient from 'services/query'
import { API_URL_ACCREDITATION_WISH_INIT } from 'constants/api'

interface LoaderParams {
  params: {
    id: string
  }
}

export const loadAccreditation = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let accreditation

    try {
      accreditation = await client.get(`${API_URL_ACCREDITATION_WISH_INIT}/${id}`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }

    return { accreditation, training: null, wish: null, trainingPlans: [] }
  }
)
