import { LOCALES } from 'constants/locales'

const storedLocale = window.localStorage.getItem('locale')

export const defaultLocale = (storedLocale
  || window.navigator.language
  || window.navigator.userLanguage
  || 'fr').substr(0, 2)

/**
 * Get locale from location pathname: '/:lang/some-route'
 * If none is found, use navigator default
 */
export function getDefaultLocale() {
  const match = /\/([^/]+)/.exec(window.location.pathname)
  const lang = match && match[1]
  return (lang && LOCALES.includes(lang)) ? lang : defaultLocale
}
