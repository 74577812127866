import * as React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'antd'
import { FieldTimeOutlined } from '@ant-design/icons'

function ReminderIcon({ count, badgeClassName, ...props }) {
  return (
    <Badge count={count} size="small" className={badgeClassName}>
      <FieldTimeOutlined {...props} />
    </Badge>
  )
}

ReminderIcon.propTypes = {
  count: PropTypes.number,
  badgeClassName: PropTypes.string,
}

export default ReminderIcon
