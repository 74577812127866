import React from 'react'
import PropTypes from 'prop-types'
import {
  CalendarOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
} from '@ant-design/icons'

const icons = {
  doc: FileWordOutlined,
  docx: FileWordOutlined,
  jpg: FileJpgOutlined,
  jpeg: FileJpgOutlined,
  pdf: FilePdfOutlined,
  png: FileImageOutlined,
  ppt: FilePptOutlined,
  pptx: FilePptOutlined,
  txt: FileTextOutlined,
  xls: FileExcelOutlined,
  xlsx: FileExcelOutlined,
  ical: CalendarOutlined,
}

const FileTypeIcon = ({ extension, ...props }) => {
  const Icon = icons[extension] || FileOutlined

  return <Icon {...props} />
}

FileTypeIcon.propTypes = {
  extension: PropTypes.string.isRequired,
}

export default FileTypeIcon
