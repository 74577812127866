import React from 'react'
import { Link, useLocation, useRouteLoaderData, useSearchParams } from 'react-router-dom'
import { Space, Tag } from 'antd'
import type { Settings, Training } from 'shared/types'
import { useI18n } from 'context/I18nProvider'
import HighLight from 'components/HighLight'
import { getCatalogCardBg } from 'services/images'
import { getBackground } from 'services/training'

interface ColorsType {
  [p: string]: string
}

const colors: ColorsType = {
  certifying: 'rgb(234, 88, 12)',
  restricted: 'rgb(14, 165, 233)',
  new: 'rgb(134, 239, 172)',
  pathway: 'rgb(192, 132, 252)',
  accreditating: 'rgb(244, 114, 182)',
}

interface CardProps {
  training: Training
  path: React.JSX.Element
  highlight?: string | undefined
}

function Card({ training, path, highlight = '' }: CardProps) {
  const { i18n, i18name } = useI18n()
  const settings = useRouteLoaderData('root') as Settings
  const { pathname } = useLocation()
  const [search] = useSearchParams()
  const imageURL = getCatalogCardBg(
    (getBackground(training) ?? settings.defaultTrainingBackground)?.url,
    training.id,
  )
  const tags = []

  if (training.certifying) tags.push({ type: 'certifying', label: 'label.certifying' })
  if (training.restricted) tags.push({ type: 'restricted', label: 'catalog.filters.onnomination' })
  if (training.newInCatalog) tags.push({ type: 'new', label: 'label.new' })
  if (training.pathway) tags.push({ type: 'pathway', label: 'label.pathway' })
  if (training.accreditating && settings.hseEnabled) tags.push({ type: 'accreditating', label: 'label.accreditating' })

  return (
    <Link
      id={`card-${training.id}`}
      className="flex flex-col bg-white h-[260px] rounded-2xl overflow-hidden"
      to={`${pathname}/${training.id}${search.has('q') ? `?q=${search.get('q')}` : ''}`}
    >
      <div
        className="min-h-[111px] max-h-[111px] bg-cover bg-center bg-gray-200"
        {...(imageURL ? { style: { backgroundImage: `url(${imageURL})` } } : {})}
      />
      <div className="flex flex-col justify-between h-full p-4">
        <span className="text-slate-800 font-bold overflow-ellipsis line-clamp-3">
          <HighLight word={highlight} title={i18name(training)}>{i18name(training)}</HighLight>
        </span>
        <span className="text-gray-400 overflow-ellipsis line-clamp-2">
          {path}
        </span>
        <div className="grow" />
        <Space size={[0, 8]} wrap>
          {tags.map(tag => (
            <Tag
              key={tag.type}
              color={colors[tag.type]}
              className="text-xs uppercase align-middle drop-shadow"
            >
              {i18n(tag.label)}
            </Tag>
          ))}
        </Space>
      </div>
    </Link>
  )
}

export default Card
