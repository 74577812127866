import {
  API_ADMIN_PROFILES,
  API_ADMIN_PERMISSIONS,
  API_ADMIN_SUB_PROFILES,
} from 'constants/api-v2'
import QueryClient from 'services/query'

interface Params {
  params: {
    id: string
  }
}

export const loadAdminProfile = (client: QueryClient) => (
  async ({ params }: Params) => {
    const { id } = params

    try {
      // eslint-disable-next-line prefer-const
      const [profile, permissions] = await Promise.all([
        client.get(`${API_ADMIN_PROFILES}/${id}`),
        client.get(`${API_ADMIN_PERMISSIONS}?vintage=false`),
      ])

      if (profile.type !== 'collaborator' && profile.type !== 'manager') {
        const profileAssignments = await client.get(`${API_ADMIN_PROFILES}/${id}/assignments?pagination=false`)

        return { profile, permissions: permissions.items, profileAssignments: profileAssignments.items }
      }

      return { profile, permissions: permissions.items, profileAssignments: [] }
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }

    return null
  }
)

export const loadSubProfile = (client: QueryClient) => (
  async ({ params }: Params) => {
    const { id } = params

    try {
      const [subProfile, permissions] = await Promise.all([
        client.get(`${API_ADMIN_SUB_PROFILES}/${id}`),
        client.get(`${API_ADMIN_PERMISSIONS}?subprofile=true`),
      ])
      return { subProfile, permissions: permissions.items }
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }

    return null
  }
)
