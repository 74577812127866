import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'context/I18nProvider'
import Button from 'components/UI/Button'
import Form, { Center, H1, H2 } from './Decorators'

const ResetPasswordConfirm = () => {
  const { i18n, locale } = useI18n()
  const navigate = useNavigate()

  const goBack = () => navigate(`/${locale}/login`)

  useEffect(() => {
    const h = window.setTimeout(goBack, 5000)
    return () => window.clearTimeout(h)
  })

  return (
    <Form onSubmit={goBack}>
      <H1>{i18n('login-page.update.password.confirm')}</H1>
      <H2>{i18n('login-page.update.password.redirect')}</H2>
      <Center>
        <Button onClick={goBack}>{i18n('login-page.login.button')}</Button>
      </Center>
    </Form>
  )
}

export default ResetPasswordConfirm
