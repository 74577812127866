import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { useOnClickOutside } from 'hooks/utils'

function ConfirmInput({ value, setValue, onConfirm, onCancel, inputProps, cancelOnClickOutside }) {
  const [edition, setEdition] = useState(false)
  const inputRef = useRef(null)

  const enterEditionMode = () => {
    setEdition(true)
  }

  useEffect(() => {
    if (edition) inputRef.current.select()
  }, [edition])

  const confirm = () => {
    setEdition(false)
    onConfirm()
  }

  const cancel = () => {
    setEdition(false)
    onCancel()
  }

  const readonly = (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span className="" onClick={enterEditionMode}>{value}</span>
      <EditOutlined className="text-[--primary]" onClick={enterEditionMode} />
    </>
  )

  const editing = (
    <>
      <input
        ref={inputRef}
        {...inputProps}
        className=""
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <CheckOutlined
        className={cx('text-[--primary]', { 'mid-opacity': !onConfirm })}
        onClick={onConfirm ? confirm : null}
      />
      <CloseOutlined className="text-red-600" onClick={cancel} />
    </>
  )

  const mainRef = useRef(null)
  useOnClickOutside(mainRef, () => {
    if (cancelOnClickOutside) {
      cancel()
    }
  })

  return (
    <div className="flex justify-center items-center" ref={mainRef}>
      { edition ? editing : readonly }
    </div>
  )
}

ConfirmInput.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  inputProps: PropTypes.object,
  cancelOnClickOutside: PropTypes.bool,
}

export default ConfirmInput
