import { redirect } from 'react-router-dom'
import { API_URL_ACCREDITATION_WISH as url } from 'constants/api'
import QueryClient from 'services/query'
import { mapAccreditationPayload } from 'services/trainingWish'
import { urlAddParams } from 'services/url'
import { getSearchFilters } from 'services/filters'

interface Params {
  id: string
  lang: string
}

export const createAccreditation = (client: QueryClient) => (
  async ({ params, request }: { params: Params, request: Request }) => {
    const { id, lang } = params
    const filters = getSearchFilters()

    const formData = await request.formData()
    const payload = mapAccreditationPayload({ formData, accreditationId: Number(id) })

    try {
      await client.post(urlAddParams(url, filters), payload)
    } catch (e: Error | any) {
      return { error: e.response }
    }

    // @TODO Default vintage
    return redirect(`/${lang}/needs-summary`)
  }
)
