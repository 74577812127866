import * as React from 'react'

export const useActiveOnClick = (
  activeDefault = false,
  activeOnClickInside = false,
) => {
  const [active, setActive] = React.useState(activeDefault)
  const [locked, setLocked] = React.useState(false)

  const ref = React.useRef()

  useOnClickOutside(
    ref,
    () => setActive(false),
    () => {
      if (activeOnClickInside) setActive(true)
    },
    locked,
  )

  return { active, setActive, ref, setLocked }
}

export function useOnClickOutside(ref, handler, handlerInside = () => {}, locked = false) {
  React.useEffect(
    () => {
      const listener = event => {
        // Do nothing if locked
        if (locked) return

        // if clicked inside..
        if (!ref.current || ref.current.contains(event.target)) {
          handlerInside()
          return
        }

        // Clicked outside
        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, handlerInside, locked],
  )
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export const useDelayedEffect = (effect, delay) => {
  React.useEffect(() => {
    const timeout = window.setTimeout(effect, delay)
    return () => window.clearTimeout(timeout)
  }, [effect, delay])
}

export const useFullPageLayout = () => {
  React.useEffect(() => {
    document.querySelector('#page-wrapper').classList.add('fullpage')
    document.querySelector('#nav-root').style.display = 'none'
    document.querySelector('#top-bar').style.display = 'none'
    return () => {
      document.querySelector('#page-wrapper').classList.remove('fullpage')
      document.querySelector('#nav-root').style.display = 'block'
      document.querySelector('#top-bar').style.display = 'flex'
    }
  }, [])
}
