/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { CaretRightOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { useProfile } from 'context/ProfileProvider'
import { filtersFromURL } from 'services/matrix'
import ExportButton from 'components/ExportButton'
import { API_URL_MATRIX_EXPORT } from 'constants/api'
import './styles.scss'
import { HeaderContext, isRoot } from './context'

function Header() {
  const { hasPermission } = useProfile()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { tree, goToTree } = useContext(HeaderContext)
  const [searchParams] = useSearchParams()

  const filters = filtersFromURL(pathname, searchParams)

  function goTo(id, url) {
    goToTree(id)
    navigate(url)
  }

  return (
    <div className="header w-full flex flex-row flex-wrap justify-end pb-8">
      {tree.length > 1 ? (
        <div className="bc w-full flex flex-row flex-wrap justify-center align-center">
          {tree.map((crumb, i) => (
            <span
              role="button"
              tabIndex={0}
              key={`${crumb.id}-${i}`}
              onKeyDown={() => goTo(crumb.id, crumb.url)}
              onClick={() => goTo(crumb.id, crumb.url)}
              className={cx('crumb flex align-center', { 'is-last': i === tree.length - 1 })}
            >
              {i !== 0 ? <CaretRightOutlined className="text-base px-[6px]" /> : ''}
              <span className="name">{crumb.name}</span>
            </span>
          ))}
        </div>
      ) : null}
      {tree.length > 1 || isRoot(pathname) ? (
        <>
          <div className="current w-1/3 text-center">
            <span className="inline-block">
              {tree.slice(-1)[0].name}
            </span>
          </div>
          <div className="export w-1/3 self-end pr-4 text-right">
            {hasPermission('ADMIN_MATRIX') && (
              <ExportButton className="ml-auto" href={`${API_URL_MATRIX_EXPORT}${filters}`} />
            )}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Header
