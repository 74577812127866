import * as React from 'react'
import { diatricsInsensitive } from 'utils/diatrics'

interface HighLightProps {
  word?: string | null
  className?: string
  children: string
  tag?: keyof JSX.IntrinsicElements // eslint-disable-line
  title?: string
}

/**
 * highlight text between html tags
 * https://stackoverflow.com/a/62838125/3060801
 */
const highlightedText = (html: string, query: string | undefined) => {
  if (!query) return html
  const escapedQuery = diatricsInsensitive(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
  const rgx = '?![^<>]*>'
  const regex = new RegExp(`(${escapedQuery})(${rgx})`, 'gi')
  return html.split(regex)
    .map((part: string) => (regex.test(part) ? `<span class="bg-yellow-300">${part}</span>` : part))
    .join('')
}

/**
 * highlight text or html depending on query parameters "q"
 * Warning: children must be a simple string
 */
export default function HighLight({
  word,
  className = '',
  tag: Tag = 'span',
  children,
  title,
  ...props
}: HighLightProps) {
  if (!children) return null

  const transformed = !!word ? highlightedText(children, word) : children

  return (
    <Tag
      className={className}
      dangerouslySetInnerHTML={{ __html: transformed || '' }}
      {...props}
    />
  )
}
