import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useI18n } from 'context/I18nProvider'

export const isRoot = pathname => pathname.endsWith('/matrix')

const HeaderContext = createContext([{}, () => { }])
const HeaderProvider = props => {
  const { i18n, locale } = useI18n()
  const { pathname } = useLocation()
  const storedTree = window.localStorage.getItem('tree')
  const INIT_TREE = useMemo(() => [{ id: -1, url: `/${locale}/catalog/matrix` }], [locale])
  const initState = isRoot(pathname)
    ? INIT_TREE
    : storedTree ? JSON.parse(storedTree) : INIT_TREE
  const [tree, setTree] = useState(initState)

  const handleTreeChange = t => {
    setTree(t)
    window.localStorage.setItem('tree', JSON.stringify(t))
  }

  useEffect(() => {
    window.onpopstate = () => {
      if (tree.length > 1) {
        tree.pop()
        handleTreeChange(tree)
      }
    }
  }, [tree])

  useEffect(() => {
    if (isRoot(pathname)) setTree(INIT_TREE)
  }, [INIT_TREE, pathname])

  const updateTree = useCallback(value => {
    handleTreeChange([...tree, value])
  }, [tree])

  const goToTree = useCallback(id => {
    const index = tree.findIndex(t => t.id === id)
    const newTree = [...tree]
    newTree.length = index + 1
    handleTreeChange(newTree)
  }, [tree])

  const treeImproved = (isRoot(pathname) ? INIT_TREE : tree)
    .map(item => item.id === -1 ? ({ ...item, name: i18n('job_reference') }) : item)

  const memoizedValue = useMemo(() => ({
    tree: treeImproved,
    updateTree,
    goToTree,
  }), [goToTree, treeImproved, updateTree])

  return (
    <HeaderContext.Provider value={memoizedValue}>
      {props.children}
    </HeaderContext.Provider>
  )
}

HeaderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { HeaderContext, HeaderProvider }
