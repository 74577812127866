import { Modal } from 'antd'
import { useRevalidator } from 'react-router-dom'
import type { Document } from 'shared/types'
import { useNotification } from 'context/NotificationProvider'
import { useQuery } from 'context/QueryProvider'
import { API_DOCUMENTS } from 'constants/api-v2'
import { useI18n } from 'context/I18nProvider'
const { confirm } = Modal

export function useDeleteDocument(setLoading?: (isLoading: boolean) => void) {
  const { client } = useQuery()
  const { notifyError } = useNotification()
  const revalidator = useRevalidator()
  const { i18n } = useI18n()

  return (document: Document) => {
    confirm({
      title: i18n('popup.confirm_delete_document'),
      onOk() {
        if (setLoading) setLoading(true)
        const url = `${API_DOCUMENTS}/${document.id}`

        client.delete(url)
          .then(() => revalidator.revalidate())
          .catch((e: object) => notifyError(e))
          .finally(() => {
            if (setLoading) setLoading(false)
          })
      },
    })
  }
}
