import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import { useQuery } from 'context/QueryProvider'
import AsyncSelect from 'components/UI/AsyncSelect'
import { useNotification } from 'context/NotificationProvider'
import { API_URL_MATRIX_LINK, API_URL_TRAININGS } from 'constants/api'

function AddTrainingBlock({ onAdd, coordinates, trainings }) {
  const { i18n, i18name } = useI18n()
  const { notifyError } = useNotification()
  const { client } = useQuery()

  const [training, setTraining] = useState(null)

  const addLink = () => {
    client.post(API_URL_MATRIX_LINK, {
      job: coordinates.jobId,
      skill: coordinates.skillId,
      level: coordinates.levelId,
      training: training.value,
    })
      .then(onAdd)
      .catch(e => notifyError(e))

    setTraining(null)
  }

  return (
    <>
      <span className="matrix_addtrainingbloc_label">{i18n('matrix.newmodule')}</span>
      <div className="matrix_addtrainingbloc">
        <AsyncSelect
          placeholder={null}
          onChange={item => setTraining(item)}
          url={`${API_URL_TRAININGS}?visibleInCatalog=true`}
          value={training}
          mapper={item => ({ label: `${item.code ? item.code : ''} ${i18name(item)}`, value: item.id })}
          isOptionDisabled={option => trainings.includes(option.value)}
          defaultOptions
          insideModal
        />
        <PlusCircleOutlined onClick={() => { if (!!training) addLink() }} className="text-[--primary]" />
      </div>
    </>
  )
}

AddTrainingBlock.propTypes = {
  onAdd: PropTypes.func.isRequired,
  coordinates: PropTypes.shape({
    jobId: PropTypes.number.isRequired,
    skillId: PropTypes.number.isRequired,
    levelId: PropTypes.number,
  }).isRequired,
  trainings: PropTypes.arrayOf(PropTypes.number),
}

export default AddTrainingBlock
