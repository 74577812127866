import * as React from 'react'
import PropTypes from 'prop-types'
import { RouterProvider } from 'react-router-dom'
import QueryClient from 'services/query'
import { initSentry } from 'services/sentry'
import { encodedURL } from 'services/url'
import { useAuth } from 'context/AuthProvider'
import { router } from './router'
import { publicRouter } from './router-public'

import './app.sass'

initSentry()

export default function App({ client }) {
  const { user } = useAuth()
  let from = encodedURL()

  // Do not include `?from` on these pages
  from = ['404', 'login'].some(error => from.includes(error)) ? '/' : from

  return user
    ? <RouterProvider router={router({ queryClient: client })} />
    : <RouterProvider router={publicRouter({ queryClient: client, from })} />
}

App.propTypes = {
  client: PropTypes.instanceOf(QueryClient),
}
