// inspired from : https://stackoverflow.com/a/32756572/3060801
const diacritics = [
  'aAÀÁÂÃÄÅàáâãäåĀāąĄ',
  'cCÇçćĆčČ',
  'dDđĐďĎ',
  'eEÈÉÊËèéêëěĚĒēęĘ',
  'iIÌÍÎÏìíîïĪī',
  'lLłŁ',
  'nNÑñňŇńŃ',
  'oOÒÓÔÕÕÖØòóôõöøŌō',
  'rRřŘ',
  'sSŠšśŚ',
  'tTťŤ',
  'uUÙÚÛÜùúûüůŮŪū',
  'yYŸÿýÝ',
  'zZŽžżŻźŹ',
]

const byChar = c => {
  for (let i = 0; i < diacritics.length; i += 1) {
    const accents = diacritics[i]
    if (accents.indexOf(c) >= 0) {
      return `[${accents}]{1}`
    }
  }
  return c
}

/**
 * Look at the tests :
 *
 * expect(match('élèctronique', 'electronique')).toBe(true)
 * expect(match('bônjour', 'boñjour')).toBe(true)
 */
export const diatricsInsensitive = q => {
  let transformed = ''
  for (let i = 0; i < q.length; i += 1) {
    transformed += byChar(q[i])
  }
  return transformed
}
