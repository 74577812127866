/* eslint-disable react/prop-types */
import React from 'react'
import { Outlet, Route } from 'react-router-dom'
import Routes from 'components/RoutesWithErrorElement'
import ForgottenConfirm from './Components/ForgottenConfirm'
import ForgottenPassword from './Components/ForgottenPassword'
import ResetPassword from './Components/ResetPassword'
import ResetPasswordConfirm from './Components/ResetPasswordConfirm'

export function LoginDecorator({ children }) {
  return (
    <div className="w-full h-full my-auto flex justify-center items-center">
      {children}
      <Outlet />
    </div>
  )
}

function LoginIndex() {
  return (
    <Routes>
      <Route path="*" element={<LoginDecorator />}>
        <Route path="forgotten-password" element={<ForgottenPassword />} />
        <Route path="forgotten-password/confirmation" element={<ForgottenConfirm />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="reset-password/confirmation" element={<ResetPasswordConfirm />} />
      </Route>
    </Routes>
  )
}

export default LoginIndex
