import { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useQuery } from 'context/QueryProvider'
import { IMAGE_BLANK_DATA } from 'constants/image'
import { triggerDownloadBlob } from 'utils/binary'
import { isStatelessResource } from 'utils/environment'
import { getFilenameFromContentDisposition } from 'utils/headers'
import { useNotification } from 'context/NotificationProvider'

export function useBlob() {
  const { client } = useQuery()
  const { notifyError } = useNotification()

  const downloadBlob = useCallback(async (url, target, headers) => {
    const response = await client.request(
      url,
      { plainResponse: true, responseType: 'arraybuffer', validateStatus: s => s >= 200 && s < 500 },
      headers,
    )

    if (response && response.status >= 400 && response.status < 500) {
      if (response.data instanceof ArrayBuffer) {
        try {
          const decodedData = new TextDecoder().decode(response.data)
          notifyError({ response: { data: JSON.parse(decodedData), status: response.status } })
        } catch (e) {
          notifyError()
        }
        return
      }
      notifyError(response)
      return
    }

    if (response && response.data) {
      triggerDownloadBlob(
        response.data,
        response.headers['content-type'] || 'application/octet-stream',
        getFilenameFromContentDisposition(response.headers['content-disposition']),
        target,
      )
    }
  }, [client, notifyError])

  return {
    downloadBlob,
  }
}

export const useResourceUrl = (url, blankData = null) => {
  const { client } = useQuery()

  const [resourceUrl, setResourceUrl] = useState(blankData)
  const lastAxiosCancelSource = useRef(null)
  const lastObjectUrl = useRef(null)

  useEffect(() => {
    async function getImageBlob() {
      if (url) {
        if (isStatelessResource(url)) {
          if (lastAxiosCancelSource.current) {
            lastAxiosCancelSource.current.cancel()
          }
          const source = axios.CancelToken.source()
          lastAxiosCancelSource.current = source

          const response = await client.request(url, {
            responseType: 'arraybuffer',
            cancelToken: source.token,
            plainResponse: true,
          })

          const file = new Blob(
            [response.data],
            { type: response.headers['content-type'] || 'application/octet-stream' },
          )

          const fileUrl = window.URL.createObjectURL(file)

          if (lastObjectUrl.current) {
            // Free memory
            window.URL.revokeObjectURL(lastObjectUrl.current)
          }
          lastObjectUrl.current = fileUrl
          setResourceUrl(fileUrl)
        } else {
          setResourceUrl(url)
        }
      } else {
        // Put back initial data in case of removed url
        setResourceUrl(blankData)
      }
    }

    getImageBlob()

    return function cleanup() {
      // Cancel any fetch request
      if (lastAxiosCancelSource.current) {
        lastAxiosCancelSource.current.cancel()
      }

      if (lastObjectUrl.current) {
        // Free memory on unmount
        window.URL.revokeObjectURL(lastObjectUrl.current)
      }
    }
  }, [client, url, blankData])

  return [resourceUrl, setResourceUrl]
}

export const useImageUrl = url => useResourceUrl(url, IMAGE_BLANK_DATA)
