import { useRouteLoaderData } from 'react-router-dom'
import { LOCALES } from 'constants/locales'
import { defaultLocale } from 'utils/locale'

/**
 * Get locale in that order:
 * - localStorage
 * - browser
 * If none is found or locale is not in ENV, use 'fr'
 */
function getUserDefaultLocale() {
  const lang = defaultLocale
  return LOCALES.includes(lang) ? lang : 'fr'
}

export function useLocale() {
  const settings = useRouteLoaderData('root')

  // Use a default value in case there is a 401 while loading the settings, so it does not block loading the app
  const { locales } = settings || { locales: ['fr'] }

  // If the ENV has only one locale we use it
  if (locales?.length === 1) return { locale: locales[0] }

  if (locales && locales?.length) {
    const lang = defaultLocale
    if (locales.includes(lang)) return { locale: getUserDefaultLocale() }
  }

  return { locale: null }
}
