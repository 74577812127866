import * as React from 'react'
import BaseAsyncSelect from 'components/Filters/BaseAsyncSelect'
import { API_URL_TEACHING_METHODS } from 'constants/api'

export default function Projects({ ...props }) {
  return (
    <BaseAsyncSelect
      name="teachingMethods"
      label="label.teaching_method"
      url={API_URL_TEACHING_METHODS}
      {...props}
    />
  )
}
