import * as React from 'react'
import { Navigate, useParams, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { Space } from 'antd'
import { ClockCircleFilled, FileFilled, ScheduleFilled, UploadOutlined } from '@ant-design/icons'
import { useModal } from 'react-modal-hook'
import type { Settings } from 'shared/types'
import { useAuth } from 'context/AuthProvider'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import Page403 from 'components/Page403'
import { API_USERS } from 'constants/api-v2'
import useSWR from 'hooks/swr'
import Spin from 'components/UI/Spin'
import { urlAddParams } from 'services/url'
import { CertificateWishType } from 'constants/accreditations'
import type { Auth } from './types'
import { ButtonWhite } from './Buttons'
import { Content } from './Content'
import { Documents } from './Documents'
import { Header } from './Header'
import { Historical } from './Historical'
import { Trainings } from './Trainings'
import { Accreditations } from './Accreditations'
import UploadDocumentModal from './UploadDocumentModal'

/**
 * To be able to use the current user `id` in our loader we redirect here
 */
export function RedirectUser() {
  const { user } = useAuth() as Auth

  return <Navigate to={user.id.toString()} />
}

export default function Dashboard() {
  const settings = useRouteLoaderData('root') as Settings
  const { hseEnabled } = settings
  const { user } = useAuth() as Auth
  const { profile, hasPermission } = useProfile()
  const { id } = useParams()
  const url = urlAddParams(`${API_USERS}/${id}`, { groups: ['users:view', 'users:view:medium'] })
  const { data, isValidating } = useSWR(url)
  const navigate = useNavigate()
  const { i18n, locale } = useI18n()

  const [show, hide] = useModal(() => (
    <UploadDocumentModal onCancel={hide} />
  ), [])

  if (isValidating) return <Spin />

  if (
    (
      (user.id !== Number(id) && profile.type === 'collaborator')
      || (user.id === Number(id) && profile.type !== 'collaborator')
    )
    && !profile.canViewDashboard
  ) {
    return <Page403 />
  }

  return (
    <div className="flex flex-col">
      <div className="mb-8">
        <Header user={data} />
      </div>

      <div className="mb-8">
        <Trainings />
      </div>

      {(hasPermission('ACCREDITATION_CAN_SEE_WISH')
        || hasPermission('RENEWABLE_TRAINING_DASHBOARD')
        || hasPermission('TRAINING_HISTORY_CAN_VIEW')
      ) && (
        <div className="flex space-x-8 mb-8">
          {(hseEnabled && hasPermission('ACCREDITATION_CAN_SEE_WISH')) && (
            <Content
              icon={<ScheduleFilled />}
              title={i18n('label.my.accreditations.hse')}
              className="flex-1 flex flex-col"
              extra={
                hasPermission('ACCREDITATION_CAN_SEE_WISH') && (
                  <ButtonWhite onClick={() => navigate(`/${locale}/accreditations?user[]=${id}`)}>
                    {i18n('label.view')}
                  </ButtonWhite>
                )
              }
            >
              <Accreditations boardType={CertificateWishType.ACCREDITATION} />
            </Content>
          )}
          {(!hseEnabled && hasPermission('RENEWABLE_TRAINING_DASHBOARD')) && (
            <Content
              icon={<ScheduleFilled />}
              title={i18n('label.my.renewable.trainings')}
              className="flex-1 flex flex-col"
              extra={
                hasPermission('RENEWABLE_TRAINING_DASHBOARD') && (
                  <ButtonWhite onClick={() => navigate(`/${locale}/renewable-trainings?user[]=${id}`)}>
                    {i18n('label.view')}
                  </ButtonWhite>
                )
              }
            >
              <Accreditations boardType={CertificateWishType.RENEWABLE_TRAINING} />
            </Content>
          )}

          {hasPermission('TRAINING_HISTORY_CAN_VIEW') && (
            <Content
              icon={<ClockCircleFilled />}
              title={i18n('label.my-historical')}
              className="flex-1 flex flex-col"
              extra={(
                <ButtonWhite onClick={() => navigate(`/${locale}/training-history?user[]=${id}`)}>
                  {i18n('label.view')}
                </ButtonWhite>
              )}
            >
              <Historical />
            </Content>
          )}
        </div>
      )}

      <div className="mb-12">
        <Content
          icon={<FileFilled />}
          title={i18n('label.my-documents')}
          extra={(
            <ButtonWhite onClick={show}>
              <Space><UploadOutlined />{i18n('session.participants.upload-document')}</Space>
            </ButtonWhite>
          )}
        >
          <Documents />
        </Content>
      </div>
    </div>
  )
}
