const LANG_TO_COUNTRY = {
  en: 'gb',
  fr: 'fr',
  de: 'de',
}

export const LANG_ISO = {
  de: 'de-DE',
  en: 'en-GB',
  fr: 'fr-FR',
}

export const langToCountry = lang => LANG_TO_COUNTRY[lang] || lang
