import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

/**
 * The most simple clickable element
 */
const Clickable = forwardRef(({ onClick, children, ...props }, ref) => (
  <div
    ref={ref}
    onClick={onClick}
    role="button"
    onKeyPress={onClick}
    tabIndex={0}
    {...props}
  >
    { children }
  </div>
))

Clickable.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Clickable
