export const ACCOMPLISHED_STATUS = 'accomplished'
export const ALLOWED_STATUS = 'allowed'
export const CANCELED_STATUS = 'canceled'
export const DELETED_STATUS = 'deleted'
export const DISALLOWED_CONFIRMED_STATUS = 'disallowed_confirmed'
export const DISALLOWED_STATUS = 'disallowed'
export const INITIED_STATUS = 'initied'
export const PENDING_STATUS = 'pending'
export const PENDING_CONFIRMED_STATUS = 'pending_confirmed'
export const REGISTERED_STATUS = 'registered'
export const TO_REVIEW_STATUS = 'to_review'
export const UNACCOMPLISHED_STATUS = 'unaccomplished'

export const COLORS = {
  [ACCOMPLISHED_STATUS]: '#497db9',
  [ALLOWED_STATUS]: '#27c281',
  [CANCELED_STATUS]: '#535c66',
  [DELETED_STATUS]: '#535c66',
  [DISALLOWED_CONFIRMED_STATUS]: '#727c87',
  [DISALLOWED_STATUS]: '#e43a45',
  [INITIED_STATUS]: '#43b6ad',
  [PENDING_STATUS]: '#f7ca17',
  [PENDING_CONFIRMED_STATUS]: '#b0b8be',
  [REGISTERED_STATUS]: '#673ab7',
  [TO_REVIEW_STATUS]: '#ff0063',
  [UNACCOMPLISHED_STATUS]: '#86a6cb',
}
