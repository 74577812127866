/**
 * This is very confusing and messy...
 * A bit of content and hopefuly explanation here:
 * @see https://gitlab.com/eleo-rh/training/-/issues/8285#note_1460440823
 */
export function filtersFromURL(pathName: string, params: URLSearchParams): string {
  const path = pathName.split('/').reverse()[0]
  const id = params.get('id')
  const skillType = params.get('skillType')

  let filters = ''

  switch (path) {
    case 'matrix':
      // no filters
      break
    case 'subfamily':
      filters = `?job.family=${id}`
      break
    case 'skilltypes':
      filters = `?job=${id}`
      break
    case 'graph':
      filters = `?job=${id}&skill.skillType=${skillType}`
      break
    default:
      // pass
  }

  return filters
}
