import React from 'react'
import MultiButton from 'components/UI/MultiButton'

export type FilterMultiButtonProps = {
  value: string | null | undefined
  onChange: Function
  options?: { [p: number]: null|string }
}

interface BaseMultiButtonProps {
  labels: string[]
}

export default function BaseMultiButton({
  value,
  labels,
  onChange,
  options = { 0: null, 1: 'false', 2: 'true' },
}: FilterMultiButtonProps & BaseMultiButtonProps) {
  const handleChange = (val: number) => {
    if (val === 0) onChange(options[0])
    if (val === 1) onChange(options[1])
    if (val === 2) onChange(options[2])
  }

  const initValue = () => {
    const defaultValue = Object.keys(options).find(key => options[key as unknown as number] === value) as string
    return parseInt(defaultValue, 10) ? Number(defaultValue) : defaultValue
  }

  return (
    <MultiButton
      labels={labels}
      onClick={handleChange}
      // @ts-ignore
      value={initValue()}
      btnClassName="py-0 px-2"
    />
  )
}
