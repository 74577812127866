/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import { Input as AntdInput } from 'antd'
import type { InputProps as AntdInputProps, InputRef } from 'antd'

interface InputProps {
  name?: string
  error?: boolean
  password?: boolean
}

interface ExtraInputProps {
  outline?: boolean
  helper?: string
  antdForm?: boolean
}

const Input = React.forwardRef<InputRef, InputProps & AntdInputProps & ExtraInputProps>(({
  name,
  placeholder,
  required,
  disabled,
  error,
  className,
  outline = true,
  helper,
  password = false,
  antdForm,
  ...props
}, ref) => {
  const inputRef = React.useRef<InputRef | null>(null)
  const realRef = !!ref ? ref : inputRef
  let antdRef: (InputRef | null) = null

  const [focus, setFocus] = React.useState<boolean>(false)

  const hasAddon = 'addonAfter' in props

  let placeholderText: string | undefined = placeholder
  if (required) placeholderText += ' *'

  const borderColor: string = error ? '!border-red-500' : '!border-gray-300'

  const preventChange = (e: React.WheelEvent<HTMLInputElement>) => {
    // Prevent the input value change
    (e.target as HTMLElement).blur()
    // Prevent the page/container scrolling
    e.stopPropagation()
    // Refocus immediately, on the next tick (after the current after the function is done)
    setTimeout(() => {
      (e.target as HTMLElement).focus()
    }, 0)
  }

  // Tricky part here... If the input has an addOn, the click can happen on that addon and we do not want to give focus
  // to the input in that case. Otherwise (click on wrapper, label, etc), give focus to input
  const focusInput = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = (e.target as HTMLElement)
    const clickOnAddOn = hasAddon
      && (
        target.classList.contains('ant-select-selector')
        || target.classList.contains('country-flag')
        || target.tagName === 'IMG'
        || target.tagName === 'SPAN')

    // @ts-ignore
    if (realRef.current && !clickOnAddOn) realRef.current.focus()
    if (!!antdForm) antdRef?.focus()
  }

  // Classes for input without addon
  const cssPlaceholder = `
    peer-placeholder-shown:text-base peer-placeholder-shown:top-0.5
    peer-placeholder-shown:bg-transparent peer-placeholder-shown:px-0
  `
  const cssFloating = 'peer-focus:-top-[1.17rem] peer-focus:text-sm peer-focus:bg-white peer-focus:px-2'
  // Special case with addon (main component is not the input but a span)
  const cssPlaceholderAddon = 'text-base top-0.5 bg-transparent px-0'
  const cssFloatingAddon = '-top-[1.1rem] text-sm bg-white px-2'

  const inputClassName = React.useMemo(() => {
    if (!hasAddon) return `${cssFloating} ${cssPlaceholder}`

    if ('current' in realRef) {
      const input = realRef?.current?.input
      if (!!input) {
        // input value is set to '' if we specify `type="number"` and enter characters... Therefore this check:
        const notEmpty = !!input.value || (!input.validity.valid && !input.validity.valueMissing)

        if (focus || notEmpty) return cssFloatingAddon
        return cssPlaceholderAddon
      }
    }
    return ''
  }, [cssPlaceholder, hasAddon, focus, realRef])

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    setFocus(true)
    if (!!props.onFocus) props.onFocus(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    setFocus(false)
    if (!!props.onBlur) props.onBlur(e)
  }

  const Component = password ? AntdInput.Password : AntdInput

  // Special case for Antd FormItem ref handling
  const refProps = !!antdForm
    ? { ref: (input: InputRef) => { antdRef = input } }
    : { ref: realRef }

  return (
    <div
      className={`relative w-full ${className || ''}`}
      onClick={focusInput}
    >
      <Component
        // Keep 'props' first to be sure that our props are not overwritten
        {...props}
        {...refProps}
        name={name}
        required={required}
        disabled={disabled}
        placeholder={outline ? placeholderText : placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onWheel={preventChange}
        // `ui-outline` is used in our styles to remove antd placeholder which always appear with an addon
        className={`
          peer ${outline ? 'ui-outline' : ''} w-full h-[36px] text-gray-500 rounded !border ${borderColor}
          placeholder-transparent shadow-transparent
          hover:!border-gray-300 hover:shadow-transparent focus:border-[--primary]
        `}
      />
      <div
        className={outline ? `
          ${inputClassName} absolute max-w-[90%] -top-[1.15rem] left-3 z-10
          ${!hasAddon ? 'text-sm' : ''} text-gray-500 transition-all bg-white px-2
        ` : 'invisible h-0'}
      >
        <div className={`relative ${hasAddon ? 'top-[.3rem]' : 'top-[.5rem]'} truncate`}>
          {placeholderText}
        </div>
      </div>
      {!!helper && <div className="absolute text-gray-400 text-sm pt-2 pl-2">{helper}</div>}
    </div>
  )
})

export default Input
