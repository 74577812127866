import React from 'react'
import SearchInput from 'components/UI/SearchInput'
import Section from 'components/UI/Section'

interface TopSearchProps {
  search: string
  total: number
  onChange: (str: string) => void
  placeholder: string
  totalText: string
}

export default function TopSearch({
  search,
  total,
  onChange,
  placeholder,
  totalText,
}: TopSearchProps) {
  return (
    <Section className="h-[90px] col-span-3 flex items-center" contentClassName="w-full flex items-center">
      <SearchInput defaultValue={search} onChange={onChange} placeholder={placeholder} className="w-[70%] mr-4" />
      <div className="w-[30%] text-center"><b>{total}</b>&nbsp;{totalText}</div>
    </Section>
  )
}
