import React, { useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useSWR from 'hooks/swr'
import { useI18n } from 'context/I18nProvider'
import Card from 'components/Card'
import Spinner from 'components/Spinner'
import { HeaderContext } from '../Header/context'


const SubFamily = () => {
  const { i18n, i18name, locale } = useI18n()
  const location = useLocation()
  const { updateTree } = useContext(HeaderContext)
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const basePath = `/${locale}/catalog/matrix`

  const { data: families, isValidating: familiesLoading } = useSWR(`/api/matrix/families?parent=${id}`)
  const { data: jobs, isValidating: jobsLoading } = useSWR(`/api/matrix/jobs?family=${id}`)

  const goToFamily = (familyId, name) => {
    const url = `${basePath}/subfamily?id=${familyId}`
    updateTree({ name, url, id: familyId })
    navigate(url)
  }

  const goToJob = (jobId, name) => {
    const url = `${basePath}/skilltypes?id=${jobId}`
    updateTree({ name, url, id: jobId })
    navigate(url)
  }

  if (familiesLoading || jobsLoading) {
    return (<Spinner />)
  }

  if (jobs && jobs.results && families && families.results) {
    return (
      <div className="families w-full flex justify-between">
        {families.results.map(family => (
          <Card
            onClick={() => goToFamily(family.id, i18name(family))}
            key={family.id}
            name={i18name(family)}
            image={family.image?.url}
          />
        ))}
        {jobs.results.map(job => (
          <Card onClick={() => goToJob(job.id, i18name(job))} key={job.id} name={i18name(job)} image={job.image?.url} />
        ))}
      </div>
    )
  }
  return (
    <p>{i18n('no_result')}</p>
  )
}

export default SubFamily
