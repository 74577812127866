/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import * as React from 'react'
import { PieChart, PieChartProps } from 'react-minimal-pie-chart'
import { useI18n } from 'context/I18nProvider'
import { numberWithCommas } from 'utils/number'

const EMPTY = [
  {
    color: '#fff',
    title: '',
    value: 0,
  },
]

interface DonutProps {
  title: string
  data: {
    title: string
    value: number
    color: string
    show?: boolean
  }[]
  total?: string
  showLegend?: boolean
  showTotal?: boolean
  pieChartProps?: PieChartProps
}

export default function Donut({
  title,
  data = [],
  total,
  pieChartProps,
  showLegend,
  showTotal = true,
}: DonutProps) {
  const { locale } = useI18n()
  const [labelTitle, setLabelTitle] = React.useState(data.length && data[0].title)
  const [labelValue, setLabelValue] = React.useState(data.length && data[0].value)

  const onMouseOver = (event: any, index: number) => {
    setLabelTitle(data.length && data[index].title)
    setLabelValue(data.length && data[index].value)
  }

  React.useEffect(() => {
    setLabelTitle(data.length && data[0].title)
    setLabelValue(data.length && data[0].value)
  }, [data])

  const isEmpty = data.length === 0 || (data.length === 1 && data[0].value === 0)

  return (
    <div className="flex justify-center mr-4">
      <div className="w-[200px]">
        {title && <p className="text-xl text-center font-bold pb-4">{title}</p>}
        <div className="w-full relative z-0">
          <PieChart
            animate={false}
            animationDuration={500}
            animationEasing="ease-out"
            data={isEmpty ? EMPTY : data}
            lengthAngle={360}
            lineWidth={30}
            onMouseOver={onMouseOver}
            paddingAngle={1}
            radius={50}
            rounded={false}
            startAngle={0}
            viewBoxSize={[
              100,
              100,
            ]}
            style={{
              width: '200px',
              height: '200px',
            }}
            {...pieChartProps}
          />

          {/* Centered Label */}
          <div
            className={`
              absolute top-0 left-0 w-full h-full z-[-1]
              flex flex-wrap justify-center content-center items-center
              ${showTotal ? '-mt-4' : ''}
            `}
          >
            <div className="max-w-[60%] font-bold text-center text-[.95rem] w-full">
              {!isEmpty && labelTitle}
            </div>
            <div className="max-w-[60%] text-center text-[.9rem] w-full">
              {isEmpty ? '' : labelValue.toLocaleString(locale)}
            </div>
          </div>

          {/* Bottom Total */}
          {showTotal && total && <div className="text-center font-bold my-4 mx-0">{total}</div>}
        </div>
      </div>

      {/* Right Legend */}
      {showLegend && (
        <div className="w-fit max-w-[125px] h-[200px] mt-auto ml-6 grid py-8 grid-cols-1 gap-8">
          {data.map(dataSet => dataSet.value ? (
            <div key={`${dataSet.title}`} className="w-full flex flex-wrap items-center">
              <div className="">
                <div className="w-[10px] h-[10px] rounded-full mr-6" style={{ backgroundColor: dataSet.color }}></div>
              </div>
              <div className="grow flex flex-wrap text-[.8rem]">
                <span className="w-full">{numberWithCommas(dataSet.value)}</span>
                <span className="w-full">{dataSet.title}</span>
              </div>
            </div>
          ) : null)}
        </div>
      )}
    </div>
  )
}
