import type { TrainingWish } from 'shared/types'
import { iriToId } from 'services/apiPlatform'
import { INITIED_STATUS } from 'constants/trainingWishStatus'

interface MapPayloadProps {
  formData: FormData
  edit?: boolean
  trainingId?: number
  simpleForm?: boolean
}
interface MapFreeWishPayloadProps {
  formData: FormData
}
interface Plan {
  cost: string
  percent: string
  plan: string
}
interface MapAccreditationPayloadProps {
  formData: FormData
  trainingId?: number
  accreditationId?: number
}
interface Payload {
  filters?: {
    users?: number[] | undefined
    managers?: number[] | undefined
    withInactives: boolean
    withExternals: boolean
  }
  trainingId?: number
  plans?: Plan[]
  'plans-length'?: number
  category?: string
  theme?: string
  users?: number
  managers?: number
  trainers?: string
}

export const mapPayload = ({ formData, trainingId }: MapPayloadProps) => {
  const data = Object.fromEntries(formData)

  const payload: Payload = {
    ...data,
    ...(trainingId ? { trainingId } : {}),
  }

  // Workaround for plans
  const plans: Plan[] = []
  for (let i = 0; i < Number(data['plans-length']); i += 1) {
    if (data[`plan-${i}`] !== '') {
      plans[i] = {
        cost: data[`cost-${i}`].toString(),
        percent: data[`percent-${i}`].toString(),
        plan: data[`plan-${i}`].toString(),
      }
    }
    // @ts-ignore
    delete payload[`cost-${i}`]
    // @ts-ignore
    delete payload[`percent-${i}`]
    // @ts-ignore
    delete payload[`plan-${i}`]
  }

  // Cleanup
  delete payload.managers
  delete payload.users
  delete payload['plans-length']

  return {
    ...payload,
    plans,
  }
}

export const mapFreeWishPayload = ({ formData }: MapFreeWishPayloadProps) => {
  const payload = mapPayload({ formData })

  return {
    ...payload,
    // eslint-disable-next-line max-len
    ...(payload.category ? { category: !Number.isNaN(payload.category) ? iriToId(payload.category) : payload.category } : {}),
    ...(payload.theme ? { theme: !Number.isNaN(payload.theme) ? iriToId(payload.theme) : payload.theme } : {}),
    trainers: (JSON.parse(payload.trainers ?? '[]') ? (JSON.parse(payload.trainers ?? '[]')) : []).map((t: any) => ({
      trainer: t.trainer?.id,
      organization: t.organization?.id,
    })),
  }
}

export const mapAccreditationPayload = ({ formData, trainingId, accreditationId }: MapAccreditationPayloadProps) => {
  const payload = mapPayload({ formData })

  return {
    trainingId,
    accreditationId,
    ...payload,
  }
}

export const getCost = (wish: TrainingWish) => {
  if (wish?.currentProvisions) return wish.currentProvisions * wish.training.cost
  return wish.training.cost
}

// NeedsSummary GroupActions
export const hasArbitrateAction = ((rows: TrainingWish[]) => (
  rows.filter((row: TrainingWish) => !!row?.actions?.arbitrate))
)
export const hasConfirmAction = ((rows: TrainingWish[]) => (
  rows.filter((row: TrainingWish) => !!row?.actions?.confirm))
)
export const hasValidateAction = ((rows: TrainingWish[]) => (
  rows.filter((row: TrainingWish) => !!row?.actions?.validate))
)
export const hasValidateActionAndStatus = ((rows: TrainingWish[]) => (
  rows.filter((row: TrainingWish) => !!row?.actions?.validate && row.status !== INITIED_STATUS)
))
