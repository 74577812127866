/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import cx from 'classnames'
import useSWR from 'hooks/swr'
import { useI18n } from 'context/I18nProvider'
import Spinner from 'components/Spinner'
import EditIcon from './EditIcon'
import ModulesAvailable from './ModulesAvailable'
import Modal from './Modal'
import './styles.scss'

function getParams(search) {
  const params = new URLSearchParams(search)
  const jobId = parseInt(params.get('id'), 10)
  const skillsId = parseInt(params.get('skillType'), 10)
  return { jobId, skillsId }
}

function parseLinks(links, skills) {
  const linksBySkills = skills
    .map(skill => ({ [skill.id]: links.filter(link => link.skill.id === skill.id) }))
    .reduce((prev, curr) => {
      const skillId = Object.keys(curr)[0]
      // eslint-disable-next-line no-param-reassign
      prev[skillId] = curr[skillId]
      return prev
    }, {})
  return mapValues(linksBySkills, lbs => groupBy(lbs, 'level.id'))
}

/**
 * Creates a graph input array that fills in missing elements with virtual ones to ensure a minimum graph height & width.
 *
 * @param {Array} dataArray - The array of actual data elements.
 * @param {string} virtualPropertyName - The name of the property of the virtual elements for component unicity in empty cells.
 * @param {string} [virtualPosition='end'] - The position where the virtual elements should be added ('start' or 'end').
 * @returns {Array} The new array containing the actual and virtual elements.
 */
function getFilledArray(dataArray, virtualPropertyName, virtualPosition = 'end') {
  const createVirtualArray = size => Array(size).fill().map(() => ({ [virtualPropertyName]: Math.random() }))
  const numVirtualElements = Math.max(MINIMUM_AXIS_COUNT - (dataArray?.length || 0), 0)
  const virtualElements = createVirtualArray(numVirtualElements)
  if (virtualPosition === 'start') {
    return dataArray ? [...virtualElements, ...dataArray] : createVirtualArray(MINIMUM_AXIS_COUNT)
  }
  return dataArray ? [...dataArray, ...virtualElements] : createVirtualArray(MINIMUM_AXIS_COUNT)
}

const NO_CELL_SELECTED = { skillId: null, levelId: null, edit: false }
const MINIMUM_AXIS_COUNT = 4

function Graph() {
  const { i18name, locale } = useI18n()
  const location = useLocation()
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [selectedCell, setSelectedCell] = useState(NO_CELL_SELECTED)
  const [modalData, setModalData] = useState({})

  const { jobId, skillsId } = getParams(location.search)
  const { isValidating, mutate } = useSWR(`/api/matrix/graph/${jobId}/${skillsId}`, {
    onSuccess: resp => setData(() => resp),
  })
  const x = getFilledArray(data?.skills, 'xVirtualId', 'end')
  const y = getFilledArray(data?.levels, 'yVirtualId', 'start')

  const dataForGraph = useMemo(() => (
    data ? parseLinks(data.links, data.skills) : []
  ), [data])

  const [showModal, hideModal] = useModal(() => (
    <Modal data={modalData} onChange={() => mutate()} onClose={hideModal} navigate={navigate} />
  ), [modalData])

  useEffect(() => {
    const { skillId, levelId, edit } = selectedCell

    const links = skillId && levelId
      ? dataForGraph[skillId][levelId]
      : skillId
        ? dataForGraph[skillId].undefined
        : []

    setModalData(() => ({
      links,
      coordinates: { jobId, skillId, levelId },
      skillId,
      edit,
    }))
  }, [data, dataForGraph, jobId, selectedCell, showModal])

  React.useEffect(() => {
    const { links, skillId, edit } = modalData
    if ((!!skillId && edit) || !!links?.length) showModal()
  }, [modalData, showModal])

  const onModuleClick = (skillId, levelId, edit) => {
    setSelectedCell({ skillId, levelId, edit })
  }

  function isFullHeight(skillId) {
    return dataForGraph[skillId] && dataForGraph[skillId].undefined
  }
  function isDoubleColumn(skillId) {
    return isFullHeight(skillId) && Object.keys(dataForGraph[skillId]).length > 1
  }

  return (
    <div className="graph-wrapper">
      {isValidating
        ? <Spinner />
        : (
          <div className="graph">
            <div className="legend-y">
              {y.map(level => (
                <div key={level.id || level.yVirtualId} className="legend-y-block">
                  <div className="legend-y-text">
                    <span>{i18name(level)}</span>
                  </div>
                  <div className="legend-y-circle" />
                </div>
              ))}
            </div>
            {x.map(({ id: skillId, translations: { [locale]: { name } = {} } = {}, xVirtualId }, i) => (
              <div key={`${skillId || xVirtualId}-${i}`} className="column">
                {y.map(({ id: levelId }, yIndex) => (
                  <div
                    key={`${skillId || xVirtualId}-${yIndex}`}
                    className={cx({
                      'special-bloc': isDoubleColumn(skillId),
                      'normal-bloc': !isDoubleColumn(skillId),
                    })}
                  >
                    {isFullHeight(skillId) ? (
                      <div className={cx('block block-left', { first: yIndex === 0 })}>
                        {yIndex === 0 ? (
                          <ModulesAvailable
                            isFullHeight
                            blockNb={y.length}
                            onClick={() => onModuleClick(skillId, undefined, false)}
                            number={dataForGraph[skillId].undefined.length}
                          />
                        ) : null}
                        <EditIcon onClick={() => onModuleClick(skillId, levelId, true)} yPosition={yIndex} />
                      </div>
                    ) : null}
                    <div
                      className={cx('block', {
                        hidden: isFullHeight(skillId) && !isDoubleColumn(skillId),
                        'block-right': isDoubleColumn(skillId),
                        first: isDoubleColumn(skillId) && yIndex === 0,
                      })}
                    >
                      {dataForGraph[skillId] && dataForGraph[skillId][levelId] ? (
                        <ModulesAvailable
                          onClick={() => onModuleClick(skillId, levelId, false)}
                          number={dataForGraph[skillId][levelId].length}
                        />
                      ) : null}
                      {skillId
                          && levelId
                          && (
                            <EditIcon
                              onClick={() => onModuleClick(skillId, levelId, true)}
                              yPosition={yIndex}
                            />
                          )}
                    </div>
                  </div>
                ))}
                <div className={cx('legend-x', { 'two-column': isDoubleColumn(skillId) })}>
                  <div className="legend-x-block">
                    {skillId && (<EditIcon onClick={() => onModuleClick(skillId, undefined, true)} />)}
                    <div className="legend-x-circle" />
                    {name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  )
}

export default Graph
