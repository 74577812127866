import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useI18n } from 'context/I18nProvider'
import Donut from 'components/UI/Donut'
import ConfirmInputNumber from 'components/ConfirmInput/ConfirmInputNumber'
import { colors } from 'constants/colors'

/* eslint-disable react/prop-types */
function Cell({ title, children }) {
  return (
    <div className="w-1/4 flex flex-col text-center">
      <div className="w-full font-bold mb-4">{title}</div>
      {children}
    </div>
  )
}
/* eslint-enable react/prop-types */

function Distribution({ trainingWish, nbUsers, onInitialChange }) {
  const { i18n } = useI18n()
  const [initial, setInitial] = useState(trainingWish.initial_provisions)

  const assigned = trainingWish.initial_provisions - trainingWish.current_provisions + nbUsers
  const remaining = initial - assigned

  const cancel = () => {
    setInitial(trainingWish.initial_provisions)
  }

  const donut = [{
    color: colors.green,
    title: i18n('assign_provision_assigned'),
    value: assigned > initial ? initial : assigned,
  }, {
    color: colors.pink,
    title: i18n('assign_provision_remaining'),
    value: remaining < 0 ? 0 : remaining,
  }]
  const percent = Math.min(Math.ceil((assigned / initial) * 100), 100)
  const total = `${i18n('assign_provision_assignation')} ${percent} %`

  const min = Math.max(assigned, 1)
  const max = trainingWish.initial_provisions
  const alertMessage = i18n('assign_provision_initial_quantity_message')
    .replace('{min}', min)
    .replace('{max}', max)

  return (
    <div className="w-full flex items-center mt-8">
      <Cell title={i18n('assign_provision_initial_quantity')}>
        <ConfirmInputNumber
          value={initial}
          setValue={v => setInitial(v)}
          onConfirm={() => onInitialChange(initial)}
          onCancel={cancel}
          min={min}
          max={max}
          alertMessage={alertMessage}
          cancelOnClickOutside
        />
      </Cell>
      <Cell title={i18n('assign_provision_assigned_quantity')}>
        <span className="quantity assigned">{assigned}</span>
      </Cell>
      <Cell title={i18n('assign_provision_remaining_quantity')}>
        <span className="quantity remaining">{remaining < 0 ? 0 : remaining}</span>
      </Cell>
      <Donut
        data={donut}
        total={total}
        pieChartProps={{ radius: 45, startAngle: -90, lineWidth: 40 }}
      />
    </div>
  )
}

Distribution.propTypes = {
  trainingWish: PropTypes.shape({
    initial_provisions: PropTypes.number,
    current_provisions: PropTypes.number,
  }),
  nbUsers: PropTypes.number,
  onInitialChange: PropTypes.func,
}

export default Distribution
