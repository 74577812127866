/* eslint-disable max-len */
import React from 'react'
import { createRoutesFromElements, Route } from 'react-router-dom'
import loadable from '@loadable/component'
import { sentryCreateBrowserRouter } from 'services/sentry'
import PublicAppContainer from 'components/PublicAppContainer'
import { LoginDecorator } from 'containers/Login'
import ErrorPage from 'components/UI/ErrorPage'
import Page404 from 'components/Page404'
import Redirect from 'components/Redirect'
import Spinner from 'components/Spinner'
import { loadSettings } from 'loaders/settings'
import { login } from 'actions/login'

// The following will be lazy loaded:
const props = { fallback: <Spinner /> }
const Login = loadable(() => import('containers/Login/Components/Login'), props)
const LoginRoutes = loadable(() => import('containers/Login'), props)
const Sso = loadable(() => import('containers/Sso'), props)
const Survey = loadable(() => import('containers/PublicSurvey'), props)

export const publicRouter = ({ queryClient, from }) => sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route id="root" errorElement={<ErrorPage />} loader={loadSettings(queryClient)} shouldRevalidate={() => false}>
      {/* Old routes being redirected */}
      <Route exact path="/login" element={<Redirect path="login" />} />
      <Route exact path="/security/login-form" element={<Redirect path="login" />} />

      {/* Public routes (no profile, no sidebar) */}
      <Route
        path="/:lang/login"
        element={<PublicAppContainer><LoginDecorator><Login /></LoginDecorator></PublicAppContainer>}
        action={login(queryClient)}
      />
      <Route path="/:lang/login/*" element={<PublicAppContainer><LoginRoutes /></PublicAppContainer>} />
      <Route path="/:lang/survey/*" element={<PublicAppContainer><Survey /></PublicAppContainer>} />

      {/* SSO (from portal or training api) */}
      <Route exact path="/sso" element={<Sso />} />

      <Route exact path="/404" element={<Page404 noSideBar />} />
      <Route path="*" element={<Redirect path={`login${!!from ? `?from=${from}` : ''}`} />} />
    </Route>,
  ),
)
