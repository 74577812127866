import * as React from 'react'
import { Badge, Space } from 'antd'
import { BookOutlined, FileTextOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { useModal } from 'react-modal-hook'
import dayjs from 'dayjs'
import { useI18n } from 'context/I18nProvider'
import Section from 'components/UI/Section'
import UserProfile from 'components/UI/UserProfile'
import { TopButton } from './Buttons'

interface User {
  id: number
  firstName: string
  lastName: string
  reference: string
  organization?: { name: string }
  site?: { name: string }
  establishment?: { name: string }
  initials: string
  employmentContract?: {
    contractType?: { name: string }
    csp?: { name: string }
    entryCompany: string
  }
  enabled: boolean
  manager?: {
    firstName: string
    lastName: string
    reference: string
  }
}

export function Header({ user }: { user: User }) {
  // @TODO Wait for API so we can fetch user
  const { i18n } = useI18n()

  const [showProfileModal, hideProfileModal] = useModal(() => (
    <UserProfile
      id={user.id}
      onClose={hideProfileModal}
    />
  ), [user])

  const companyEntry = !!user.employmentContract?.entryCompany
    ? dayjs(user.employmentContract.entryCompany).format('DD/MM/YYYY')
    : '-'

  return (
    <Section>
      <div className="flex justify-between items-center mb-2">
        <UserOutlined className="text-white bg-[--primary] text-[3rem] rounded-full p-4 mr-5" />
        <Space direction="vertical">
          <div className="text-xl font-bold">
            {`${user.firstName} ${user.lastName} (${user.reference})`}
          </div>
          <div className="text-sm text-gray-600">
            <div className="flex items-center">
              <SolutionOutlined className="text-[--secondary] !text-lg mr-2" />
              {user.organization?.name} - {user.initials} - {user.establishment?.name} - {user.site?.name}
            </div>
          </div>
          <div className="text-sm text-gray-600">
            <div className="flex items-center">
              <BookOutlined className="text-[--secondary] !text-lg mr-2" />
              {
                user.employmentContract
                  ? `${user.employmentContract?.contractType?.name}
                - ${user.employmentContract?.csp?.name} ${i18n('label.since')} ${companyEntry}`
                  : '-'
              }
            </div>
          </div>
        </Space>
        <div className="grow" />
        <Space direction="vertical" align="end">
          <Space>
            <Badge
              styles={{ indicator: { width: '1.5rem', height: '1.5rem' } }}
              color={user.enabled ? 'rgb(94,242,94)' : 'rgb(230,60,77)'}
            />
            <div className="font-bold">{user.enabled ? i18n('label.active') : i18n('label.inactive')}</div>
          </Space>
          <Space>
            <div className="font-bold">{i18n('manager')} :</div>
            <div className="">
              {user.manager ? `${user.manager?.firstName} ${user.manager?.lastName} (${user.manager?.reference})` : '-'}
            </div>
          </Space>
          <TopButton onClick={showProfileModal}>
            <Space>
              <FileTextOutlined className="text-white" />
              {i18n('dashboard.user-see-details')}
            </Space>
          </TopButton>
        </Space>
      </div>
    </Section>
  )
}
