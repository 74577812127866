/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import PropTypes from 'prop-types'
import { Space } from 'antd'
import cx from 'classnames'
import { RedoOutlined } from '@ant-design/icons'
import Button from 'components/UI/Button'
import { useBlob } from 'hooks/binary'
import { useI18n } from 'context/I18nProvider'
function BinaryDownloadLink({
  href,
  callback = null,
  target = '',
  children,
  hidePending = false,
  headers = null,
  buttonType = 'primary',
  icon = null,
  ...props
}) {
  const { downloadBlob } = useBlob()
  const { i18n } = useI18n()
  const [pending, setPending] = React.useState(false)
  const [error, setError] = React.useState()

  const fetchResource = e => {
    setPending(true)
    e.preventDefault()
    e.stopPropagation()
    downloadBlob(href, target, headers)
      .then(() => {
        if (callback) callback(e)
      })
      .catch(err => setError(err.response?.statusText))
      .finally(() => setPending(false))
  }

  return (
    <Space direction="vertical">
      <Button
        type={buttonType}
        onClick={fetchResource}
        {...props}
        className={`${props.className} ${pending && !hidePending && '!text-orange-600'}`}
        disabled={pending && !hidePending}
        loading={pending && !hidePending}
        icon={!!error ? <RedoOutlined className="text-red-500" /> : icon}
      >
        <Space direction="vertical" size={1}>
          <span
            className={cx({ 'text-xs': error || (pending && !hidePending), 'text-red-500': error && !pending })}
          >
            {children}
          </span>
          {pending && !hidePending && i18n('app.download.pending')}
          {(!!error && !pending) && <span className="text-red-500">{error}</span>}
        </Space>
      </Button>
    </Space>
  )
}

BinaryDownloadLink.propTypes = {
  href: PropTypes.string.isRequired,
  callback: PropTypes.func,
  target: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  hidePending: PropTypes.bool,
  headers: PropTypes.object,
  buttonType: PropTypes.string,
}

BinaryDownloadLink.defaultProps = {
  hidePending: false,
  buttonType: 'link',
}

export default BinaryDownloadLink
