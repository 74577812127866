import * as React from 'react'

export function TopButton({ ...props }) {
  const css = `
    text-white text-sm bg-[--primary] font-bold
    rounded-full border-2 border-solid border-transparent
    px-4 py-1 cursor-pointer
  `

  return (
    <button
      type="button"
      className={css}
      {...props}
    />
  )
}

export function ButtonWhite({ ...props }) {
  const css = `
    text-white text-sm bg-transparent font-bold
    rounded-full border-2 border-solid border-white
    px-4 py-1 cursor-pointer hover:bg-[#ffffff40] active:bg-[#ffffff80]
  `

  return (
    <button
      type="button"
      className={css}
      {...props}
    />
  )
}
