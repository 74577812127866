import * as React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'

// @TODO Working with 0, 1, 2 as values is pretty bad, real values would be better
function MultiButton({ labels, onClick, value = undefined, fontSize = null, className = null, ...props }) {
  const options = labels.map((label, i) => ({ label, value: i }))

  const textCss = fontSize || '!text-sm'

  const handleChange = ({ target: { value: val } }) => onClick(val)

  const valueProp = typeof props.defaultValue === 'undefined' ? { value } : {}

  React.useEffect(() => {
    if (labels.length !== 3) throw new Error('MultiButton **must** have 3 items')
    return () => {}
  }, [labels.length])

  return (
    <Radio.Group
      options={options}
      {...valueProp}
      onChange={handleChange}
      size="small"
      {...props}
      optionType="button"
      buttonStyle="solid"
      className={`multi-btn flex my-2 uppercase ${textCss} ${className || ''}`}
    />
  )
}

MultiButton.defaultProps = {
  value: 0,
}

MultiButton.propTypes = {
  labels: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  fontSize: PropTypes.string,
  className: PropTypes.string,
}

export default MultiButton
