import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useI18n } from 'context/I18nProvider'
import { useNotification } from 'context/NotificationProvider'

export function useDrop({ onDrop, accept, maxSize, ...props }) {
  const { i18n } = useI18n()
  const { notifyError } = useNotification()

  const { getRootProps, getInputProps, fileRejections, ...rest } = useDropzone({
    onDrop,
    accept: Object.assign({}, ...(accept || []).map(mimeType => ({ [mimeType]: [] }))),
    minSize: 0,
    maxSize,
    multiple: false,
    ...props,
  })

  useEffect(() => {
    const handleError = () => {
      const notAccepted = fileRejections.length
      const isFileTooLarge = notAccepted && fileRejections[0].size > maxSize
      if (fileRejections.length) {
        const error = isFileTooLarge
          ? i18n('session.document_size_error')
          : i18n('session.document_type_error')
        notifyError(error)
      }
    }
    handleError()
  }, [fileRejections, i18n, maxSize, notifyError])

  return {
    fileRejections,
    getInputProps,
    getRootProps,
    ...rest,
  }
}
