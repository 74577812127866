import * as React from 'react'
import useSelect from 'hooks/select'
import { BaseSelect } from './SelectComponents'
import type { BaseSelectProps } from './SelectComponents'
import InfiniteSelect from './_private/InfiniteSelect'

const AsyncSelect = (props: BaseSelectProps & { selectAll?: boolean }) => {
  const { mapper } = useSelect()

  return (
    <BaseSelect
      component={InfiniteSelect}
      mapper={mapper}
      {...props} /** @todo if apiPlatform, by default mapper should be iriMapper */
    />
  )
}

export default AsyncSelect
