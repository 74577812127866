import axios from 'axios'
import { encodedURL } from 'services/url'
import { XWWWFORMURLENCODED } from 'constants/request'
import { TOKEN_NAME } from 'constants/token'

function toFormData(data) {
  return Object.entries(data)
    .reduce(
      (formData, entry) => {
        formData.append(entry[0], entry[1])
        return formData
      },
      new URLSearchParams(),
    )
}

class QueryClient {
  locale

  controller

  headers = {
    'Content-Type': 'application/json',
  }

  constructor(locale) {
    this.locale = locale
    this.request = this.request.bind(this)
    this.get = this.get.bind(this)
    this.post = this.post.bind(this)
    this.patch = this.patch.bind(this)
    this.put = this.put.bind(this)
    this.delete = this.delete.bind(this)
    this.updateHeaders()
    this.#setupInterceptors()
    this.controller = new AbortController()
  }

  /**
   * localStorage is the unique source of truth here, like a store
   */
  updateHeaders() {
    const token = window.localStorage.getItem(TOKEN_NAME)
    const locale = this.#getDefaultLocale()
    this.headers = {
      ...this.headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      'Accept-Language': locale,
    }
  }

  #getDefaultLocale() {
    return window.localStorage.getItem('locale') || this.locale
  }

  #setupInterceptors() {
    // @see https://gitlab.com/eleo-rh/training/-/issues/8229
    axios.interceptors.request.use(
      config => ({
        ...config,
        params: {
          ...config.params,
          _locale: window.localStorage.getItem('locale'),
        },
      }),
    )

    axios.interceptors.response.use(
      response => response,
      error => {
        if (axios.isCancel(error)) return Promise.reject(error)

        if (!window.location.href.includes('login')) {
          const lang = window.localStorage.getItem('locale')
          switch (error?.response?.status) {
            case 401:
              const from = encodedURL()
              window.localStorage.removeItem(TOKEN_NAME)
              window.location = `/${lang}/login?from=${from}`
              break
            default:
              // pass
          }
        }

        return Promise.reject(error)
      },
    )
  }

  /**
   * Basic request
   */
  async request(url, options, headers) {
    const { method, data, plainResponse, responseType } = options || {
      method: 'get',
      data: {},
      plainResponse: false,
      responseType: 'json',
    }
    const resp = await axios.request({
      url,
      headers: headers || this.headers,
      method,
      data,
      responseType,
      ...options,
      signal: this.controller.signal,
    })
    return plainResponse ? resp : resp?.data
  }

  /**
   * GET
   */
  async get(url, config = {}, key = null) {
    if (key) this.requests[key] = url

    const resp = await axios.request(url, {
      ...config,
      headers: this.headers,
      method: 'get',
      signal: this.controller.signal,
    })

    return (resp.data?.['hydra:member'] ?? false)
      ? { items: resp.data['hydra:member'], count: resp.data['hydra:totalItems'] ?? undefined }
      : resp.data
  }

  /**
   * POST
   */
  async post(url, data, config) {
    const isFormData = config && config.headers && config.headers['Content-Type'] === XWWWFORMURLENCODED
    const payload = isFormData ? toFormData(data) : data

    const { headers: extraHeaders, ...rest } = config || { headers: {} }

    const resp = await axios.request(url, {
      data: payload,
      method: 'post',
      headers: {
        ...this.headers,
        ...extraHeaders,
      },
      ...rest,
    })

    return resp.data
  }

  /**
   * PATCH
   */
  async patch(url, data) {
    const resp = await axios.request(url, {
      data,
      headers: {
        ...this.headers,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
    })
    return resp.data
  }

  /**
   * PUT
   */
  async put(url, data) {
    const resp = await axios.request(url, {
      data,
      headers: this.headers,
      method: 'PUT',
    })
    return resp.data
  }

  /**
   * DELETE
   */
  async delete(url) {
    const resp = await axios.request(url, {
      headers: this.headers,
      method: 'DELETE',
    })
    return resp.data
  }
}

export default QueryClient
