import React from 'react'

import PropTypes from 'prop-types'
import { useModal } from 'react-modal-hook'

import UserIcon from 'components/UserIcon'
import Clickable from 'components/Clickable'
import UserProfile from 'components/UI/UserProfile'

function UserLabel({ id, reference, enabled, internal, label, withIcon, className }) {
  const [showProfileModal, hideProfileModal] = useModal(() => (
    <UserProfile
      id={id}
      onClose={hideProfileModal}
    />
  ), [id])

  const handleClick = () => {
    if (!id) return
    showProfileModal()
  }

  return (
    <Clickable
      data-test-reference={reference}
      className="flex items-center cursor-pointer"
      onClick={handleClick}
      title={label}
    >
      {withIcon && (<UserIcon enabled={enabled} internal={internal} />)}
      <div className={`truncate pt-[3px] hover:font-bold ${withIcon ? 'ml-2' : ''} ${className}`}>
        {label}
      </div>
    </Clickable>
  )
}

UserLabel.defaultProps = {
  enabled: true,
  internal: true,
  withIcon: true,
  className: '',
  label: '',
}

UserLabel.propTypes = {
  id: PropTypes.number,
  reference: PropTypes.string,
  enabled: PropTypes.bool,
  internal: PropTypes.bool,
  withIcon: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
}

export default UserLabel
