import { SESSION_FORMATS } from 'constants/session'

export const ATTENDANCE_PAGE_SIZE = 6
export const COST_PAGE_SIZE = 5
export const DOCTEMPLATE_ALLOWED_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
export const DOCTEMPLATE_MAX_SIZE = 1e7
export const DOCUMENTS_ALLOWED_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]
export const DOCUMENTS_MAX_SIZE = 2e7
export const IMAGES_ALLOWED_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
]
export const IMAGES_MAX_SIZE = 1e7
export const IMPORT_ALLOWED_TYPES = [
  'text/csv',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]
export const IMPORT_MAX_SIZE = 1e7
export const LONG_SESSION_ATTENDANCE_PAGE_SIZE = 3
export const MAILATTACHMENTS_ALLOWED_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]
export const MAILATTACHMENTS_MAX_SIZE = 2e7
export const SESSION_MAIL_PAGE_SIZE = 4
export const pageSizeFromFormat = (format: string) => SESSION_FORMATS.STANDARD === format
  ? ATTENDANCE_PAGE_SIZE
  : LONG_SESSION_ATTENDANCE_PAGE_SIZE
