import React from 'react'
import type { SelectValue } from 'shared/types'
import { useI18n } from 'context/I18nProvider'
import Button from 'components/UI/Button'
import Domains from 'components/Filters/Accreditations/Domains'
import type { FilterValues } from 'hooks/filters'

interface AccreditationFiltersProps {
  values: FilterValues
  reset: Function
  setValue: Function
}

export default function AccreditationFilters({
  values,
  reset,
  setValue,
}: AccreditationFiltersProps) {
  const { i18n } = useI18n()

  return (
    <div className="grid gap-y-4">
      <Domains
        value={values?.domains || []}
        onChange={(vals: SelectValue[]) => setValue('domains', vals)}
      />
      <Button type="link" onClick={() => reset()}> {i18n('label.reinit')}</Button>
    </div>
  )
}
