import { redirect } from 'react-router-dom'
import { API_COLLECTIVES } from 'constants/api-v2'
import { idToIri } from 'services/apiPlatform'

export const createCollective = client => (
  async ({ params, request }) => {
    const { id, lang } = params

    const formData = await request.formData()

    const filterEmptyOptions = key => formData.getAll(key).filter(val => !!val)

    const nonZeroInt = key => {
      const val = formData.get(key)
      if (val === '') return null
      return Number(val)
    }

    const payload = {
      categories: (filterEmptyOptions('categories') || []).map(v => idToIri('training-categories', v)),
      themes: (filterEmptyOptions('themes') || []).map(v => idToIri('training-themes', v)),
      trainings: (filterEmptyOptions('trainings') || []).map(v => idToIri('trainings', v)),
      vintages: (filterEmptyOptions('vintages') || []).map(v => idToIri('vintages', v)),
      plans: (filterEmptyOptions('plans') || []).map(v => idToIri('training-plans', v)),
      organizations: (filterEmptyOptions('organizations') || []).map(v => idToIri('organizations', v)),
      types: (filterEmptyOptions('types') || []).map(v => idToIri('training-wish-types', v)),
      reasons: (filterEmptyOptions('reasons') || []).map(v => idToIri('training-wish-reasons', v)),
      budgets: (filterEmptyOptions('budgets') || []).map(v => idToIri('training-budgets', v)),
      projects: (filterEmptyOptions('projects') || []).map(v => idToIri('projects', v)),
      orientations: (filterEmptyOptions('orientations') || []).map(v => idToIri('orientations', v)),
      perimeters: (filterEmptyOptions('perimeters') || []).map(v => idToIri('training-wish-perimeters', v)),
      initials: filterEmptyOptions('initials'),
      places: (filterEmptyOptions('places') || []).map(v => idToIri('establishments', v)),
      sites: (filterEmptyOptions('sites') || []).map(v => idToIri('sites', v)),
      trainingWishStatus: filterEmptyOptions('trainingWishStatus'),
      budgetCost: nonZeroInt('budgetCost'),
      budgetDuration: nonZeroInt('budgetDuration'),
      budgetQuantity: nonZeroInt('budgetQuantity'),
    }

    payload.translations = {
      [lang]: {
        name: formData.get('name'),
        locale: lang,
      },
    }

    let URL = API_COLLECTIVES
    if (id) URL += `/${id}`
    const clientMethod = id ? client.patch : client.post

    try {
      await clientMethod(URL, payload)
    } catch (e) {
      return { error: e }
    }
    return redirect(`/${lang}/collectives`)
  }
)
