import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

function Card({ image, name, onClick, className }) {
  return (
    <div
      className={`card ${className}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <div className="card-image">
        <img src={image} alt={name} />
      </div>
      <div className="text-[--primary] font-bold uppercase h-[60px] flex justify-center items-center">
        <p>{name}</p>
      </div>
    </div>
  )
}

Card.propTypes = {
  onClick: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Card
