import * as React from 'react'
import { Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import useSWR from 'hooks/swr'
import { useI18n } from 'context/I18nProvider'
import { API_TRAINING_SNAPSHOT } from 'constants/api-v2'
import Spin from 'components/UI/Spin'
import { ACCOMPLISHED_STATUS, UNACCOMPLISHED_STATUS } from 'constants/trainingWishStatus'

interface Entry {
  title: string
  description: string
  status: string
}

export function Historical() {
  const { id } = useParams()
  const { i18n } = useI18n()

  const columns: ColumnsType<Entry> = [
    {
      dataIndex: 'title',
      render: (_: string, record: any) => (
        <Space.Compact direction="vertical">
          <div className="text-[--primary] font-bold">{record.trainingName || '-'}</div>
          <div className="text-sm text-gray-700">
            {i18n('start_date')} : {record.sessionStart ? dayjs(record.sessionStart).format('DD/MM/YYYY') : '-'}
          </div>
        </Space.Compact>
      ),
    }, {
      dataIndex: 'attendance',
      render: (_: string, record: any) => {
        const color = record.attendance ? 'text-[--primary]' : 'text-red-500'
        return (
          <div className={`text-center font-bold ${color}`}>
            {record.attendance
              ? i18n('label.status_accomplished') : i18n('label.status_unaccomplished')}
          </div>
        )
      },
    },
  ]

  const HISTORICAL_STATUSES = [
    ...[ACCOMPLISHED_STATUS, UNACCOMPLISHED_STATUS],
  ]

  let url = API_TRAINING_SNAPSHOT
  url += `?user[]=${id}&itemsPerPage=3&order[id]=desc`
  url += HISTORICAL_STATUSES.map(s => `&status[]=${s}`).join('')
  const { data, isValidating } = useSWR(url)

  if (isValidating) return <Spin />

  return (
    <Table rowKey="@id" dataSource={data} columns={columns} showHeader={false} pagination={false} />
  )
}
