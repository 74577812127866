import * as React from 'react'
import useSWR from 'hooks/swr'
import { urlAddParams } from 'services/url'

const toCamelCase = s => s.replace(/_([a-z])/g, g => g[1].toUpperCase())
const toCamelCaseResp = resp => Object
  .entries(resp)
  .reduce((result, [key, value]) => ({ ...result, [toCamelCase(key)]: value }), {})

export const useInfiniteSWR = (url, apiPlatform) => {
  const [data, setData] = React.useState({ results: [] })
  const [page, setPage] = React.useState(1)
  const [hasNext, setHasNext] = React.useState(false)

  const pageToRequest = url === data?.url ? page : 1
  const { error, isValidating: loading } = useSWR(urlAddParams(url, { page: pageToRequest }), {
    onSuccess: resp => {
      let totalItems
      let currentPage
      let results
      let rawResp
      if (apiPlatform) {
        /**
         * ApiPlatform "decided" not to send pagination info when there are too few results in a paginated endpoint...
         */
        totalItems = resp['hydra:totalItems']
        currentPage = ('hydra:view' in resp) ? Number(resp['hydra:view']['@id'].split('page=')[1]) : 1
        results = (currentPage > 1) ? [...data.results, ...resp['hydra:member']] : resp['hydra:member']
        rawResp = resp
        setHasNext('hydra:view' in resp && 'hydra:next' in resp['hydra:view'])
      } else {
        // Legacy endpoints
        const camelCaseResp = toCamelCaseResp(resp)
        currentPage = camelCaseResp.currentPage
        results = camelCaseResp.currentPage > 1
          ? [...(data?.results || []), ...camelCaseResp.results]
          : camelCaseResp.results
        rawResp = camelCaseResp
        setHasNext(Boolean(camelCaseResp?.nextPage))
      }
      setPage(currentPage)
      setData({
        ...rawResp,
        totalItems,
        currentPage,
        url,
        results,
      })
    },
  })

  return {
    data,
    error,
    hasNext,
    next: () => setPage(data.currentPage + 1),
    loading,
  }
}
