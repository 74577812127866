import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { WarningOutlined } from '@ant-design/icons'
import Tooltip from 'components/UI/Tooltip'
import ConfirmInput from './index'

function ConfirmInputNumber({ value, setValue, onConfirm, onCancel, cancelOnClickOutside, min, max, alertMessage }) {
  const [stringValue, setStringValue] = useState(`${value}`)
  const [valid, setValid] = useState(true)

  const handleChanges = v => {
    let isValid = Math.ceil(v) === parseFloat(v)
    if (typeof min === 'number') isValid = isValid && v >= min
    if (typeof max === 'number') isValid = isValid && v <= max
    setValid(isValid)
    setStringValue(v)
    setValue(isValid ? parseInt(v, 10) : value)
  }

  const cancel = () => {
    setStringValue(`${value}`)
    setValid(true)
    onCancel()
  }

  useEffect(() => {
    setStringValue(`${value}`)
    setValid(true)
  }, [value])

  return (
    <>
      <ConfirmInput
        value={stringValue}
        setValue={handleChanges}
        onConfirm={valid ? onConfirm : null}
        onCancel={cancel}
        inputProps={{ type: 'number' }}
        cancelOnClickOutside={cancelOnClickOutside}
      />
      {
        !valid && (
          <Tooltip
            open
            placement="bottom"
            title={(
              <div className="confirm-input-number-tooltip">
                <WarningOutlined />{alertMessage}
              </div>
            )}
          >
          </Tooltip>
        )
      }
    </>
  )
}

ConfirmInputNumber.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  cancelOnClickOutside: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  alertMessage: PropTypes.string,
}

export default ConfirmInputNumber
