import dayjs from 'dayjs'

export function isJson(str: string): boolean {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

export const capitalize = (str: string) => !!str
  ? `${str.charAt(0).toUpperCase()}${str.slice(1)}`
  : ''

export const formatDate = (str: string) => dayjs(str).format('DD/MM/YYYY')
export const formatDateTime = (str: string) => dayjs(str).format('DD/MM/YYYY hh:mm')

export const fixUrl = (url: string) => url.replace('?&', '?')
