/* eslint-disable react/prop-types */
import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import ErrorPage from 'components/UI/ErrorPage'

// When there is no SideBar we want the container to be smaller and centered
const css = '!w-[800px] h-[300px] m-64 ml-[22rem]'

function Page404({ noSideBar = false }) {
  const { i18n } = useI18n()

  return (
    <ErrorPage
      className={noSideBar ? css : ''}
      customIcon={SearchOutlined}
      customError={{ statusText: i18n('page-not-found.text') }}
      title="404"
    />
  )
}

export default Page404
