// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Actif-INT.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Actif-EXT.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Inactif-INT.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Inactif-EXT.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eleo-user-icon{display:inline-block;height:26px;width:26px;background-repeat:no-repeat}.eleo-user-icon.active.internal{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.eleo-user-icon.active.external{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___})}.eleo-user-icon.inactive.internal{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_2___})}.eleo-user-icon.inactive.external{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_3___})}`, "",{"version":3,"sources":["webpack://./components/UserIcon/styles.scss"],"names":[],"mappings":"AACA,gBACE,oBAAA,CACA,WAAA,CACA,UAAA,CACA,2BAAA,CACA,gCACE,wDAAA,CAEF,gCACE,wDAAA,CAEF,kCACE,wDAAA,CAEF,kCACE,wDAAA","sourcesContent":["\n.eleo-user-icon {\n  display: inline-block;\n  height: 26px;\n  width: 26px;\n  background-repeat: no-repeat;\n  &.active.internal {\n    background-image: url('./Actif-INT.svg');\n  }\n  &.active.external {\n    background-image: url('./Actif-EXT.svg')\n  }\n  &.inactive.internal {\n    background-image: url('./Inactif-INT.svg')\n  }\n  &.inactive.external {\n    background-image: url('./Inactif-EXT.svg')\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
