import type { Profile } from 'containers/Admin/Profiles/types'
import {
  API_USER,
} from 'constants/api-v2'
import QueryClient from 'services/query'

interface User {
  profiles: Profile[]
  permissions: string[]
}

export const loadUser = (client: QueryClient) => (
  async (): Promise<User | undefined> => {
    let user
    try {
      user = await client.request(API_USER)
    } catch (e: any | unknown) {
      if (!!e.response) throw e.response
    }

    return user
  }
)
