import * as React from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname } = useLocation()

  /**
   * Automatically scrolls to top whenever pathname changes, __unless we are in the session page Tabs__
   * @see https://gitlab.com/eleo-rh/training/-/issues/9137 (Google Chrome bug only)
   */
  React.useLayoutEffect(() => {
    const sessionPath = pathname.match(/[a-z]{2}\/sessions\/\w+\/(\w+)/)
    const sessionTabs = !!sessionPath?.length && sessionPath[1] !== 'edit'

    if (!sessionTabs) window.scrollTo(0, 0)
  }, [pathname])

  return null
}
