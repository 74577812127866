import * as React from 'react'
import BaseAsyncSelect from 'components/Filters/BaseAsyncSelect'
import { API_ACCREDITATION_DOMAINS } from 'constants/api-v2'

export default function Domains({ ...props }) {
  return (
    <BaseAsyncSelect
      name="types"
      label="label.domains"
      url={API_ACCREDITATION_DOMAINS}
      apiPlatform
      {...props}
    />
  )
}
