// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

import { TOKEN_NAME } from 'constants/token'

export default class Auth {
  #queryClient

  constructor(props) {
    this.#queryClient = props.queryClient
  }

  getToken() {
    return window.localStorage.getItem(TOKEN_NAME)
  }

  deleteToken() {
    return window.localStorage.removeItem(TOKEN_NAME)
  }

  getUser() {
    let user
    try {
      user = jwt_decode(this.getToken())
    } catch {
      user = null
    }
    return user
  }

  login(token) {
    window.localStorage.setItem(TOKEN_NAME, token)
    this.#queryClient.updateHeaders()
  }

  logout() {
    window.localStorage.removeItem(TOKEN_NAME)
    this.#queryClient.updateHeaders()
  }

  validToken() {
    let token = this.getToken()
    if (!token) {
      return false
    }
    let valid
    token = jwt_decode(token)
    try {
      valid = token.exp && (token.exp * 1000 > new Date().getTime())
    } catch {
      valid = false
    }
    if (!valid) {
      this.logout()
      return false
    }
    return true
  }
}
