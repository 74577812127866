import React from 'react'
import { useI18n } from 'context/I18nProvider'
import BaseMultiButton from 'components/Filters/BaseMultiButton'
import type { FilterMultiButtonProps } from 'components/Filters/BaseMultiButton'

export default function Pathway({ value, onChange }: FilterMultiButtonProps) {
  const { i18n } = useI18n()

  return (
    <BaseMultiButton
      labels={[i18n('label.all'), i18n('filtres.freewish.training'), i18n('filtres.freewish.pathway')]}
      onChange={onChange}
      value={value}
    />
  )
}
