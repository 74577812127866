/* eslint-disable max-len */
export const MAIL_TEMPLATE_REGISTER = 'register_to_session'
export const MAIL_TEMPLATE_UNREGISTER = 'unregister_to_session'
export const MAIL_TEMPLATE_SESSION_AVAILABLE = 'training_wish_sessions_available'
export const MAIL_TEMPLATE_SURVEY = 'session_survey_generic'
export const MAIL_TEMPLATE_NOTIFICATION = 'notification'
export const MAIL_TEMPLATE_NOTIFICATION_REMINDER = 'notification_reminder'
export const MAIL_CANCEL_TRAINING_WISH_TO_REVIEW = 'cancel_training_wish_to_review'
export const MAIL_CANCEL_TRAINING_WISH_REGISTERED = 'cancel_training_wish_registered'
export const MAIL_SESSION_CANCELLED_WITH_TRAINING_WISHES_CANCELLATION = 'session_cancelled_with_training_wishes_cancellation'
export const MAIL_SESSION_CANCELLED_WITH_TRAINING_WISHES_WITHDRAWAL = 'session_cancelled_with_training_wishes_withdrawal'
export const MAIL_VALIDATE_TRAINING_WISH_LIST = 'validate_training_wish_list'
export const FREE_TRAINING_WISH_LINK_TRAINING = 'free_training_wish_link_training'

export const MAIL_TEMPLATE_ACCREDITATION_LOST_ALERT = 'accreditation_lost_alert'
export const MAIL_TEMPLATE_ACCREDITATION_ACQUIRED_ALERT = 'accreditation_acquired'
export const MAIL_TEMPLATE_ACCREDITATION_TO_VALIDATE = 'accreditation_to_validate'

export const MAIL_PREFERRED_ORDERING = [
  MAIL_TEMPLATE_SESSION_AVAILABLE,
  MAIL_TEMPLATE_REGISTER,
  MAIL_TEMPLATE_UNREGISTER,
  MAIL_TEMPLATE_NOTIFICATION,
  MAIL_TEMPLATE_NOTIFICATION_REMINDER,
  MAIL_TEMPLATE_SURVEY,
  MAIL_CANCEL_TRAINING_WISH_TO_REVIEW,
  MAIL_CANCEL_TRAINING_WISH_REGISTERED,
  MAIL_SESSION_CANCELLED_WITH_TRAINING_WISHES_CANCELLATION,
  MAIL_SESSION_CANCELLED_WITH_TRAINING_WISHES_WITHDRAWAL,
]

export const MAIL_WITH_SENDING_DELAY = [
  MAIL_TEMPLATE_NOTIFICATION,
  MAIL_TEMPLATE_NOTIFICATION_REMINDER,
  MAIL_TEMPLATE_SURVEY,
  MAIL_TEMPLATE_ACCREDITATION_LOST_ALERT,
]

/** These ones appears on Session / Participants / Mails :
 * - only if the mail was sent
 * - it is not possible to send manually this email if it was not previously sent
 */
export const MAIL_EXCEPTION_RESEND_ONLY = [
  MAIL_SESSION_CANCELLED_WITH_TRAINING_WISHES_CANCELLATION,
  MAIL_CANCEL_TRAINING_WISH_REGISTERED,
]

const mailRank = slug => {
  const rank = MAIL_PREFERRED_ORDERING.indexOf(slug)
  return rank >= 0 ? rank : Number.MAX_VALUE
}
export const mailComparator = (a, b) => mailRank(a.slug) - mailRank(b.slug)
export const mailDateComparator = (a, b) => a?.mailDelay?.sendingDate < b?.mailDelay?.sendingDate ? -1 : 1

export const whenOptionsDef = [
  { value: 'before', label: 'session.survey.trigger.before' },
  { value: 'after', label: 'session.survey.trigger.after' },
]

export const fromOptionsDef = [
  { value: 'start', label: 'session.survey.trigger.start' },
  { value: 'end', label: 'session.survey.trigger.end' },
]

const labelOption = (i18n, option) => ({ ...option, label: i18n(option.label) })

export const triggerOptions = (i18n, triggerMailSlug) => {
  if (triggerMailSlug === MAIL_TEMPLATE_ACCREDITATION_LOST_ALERT) {
    return ({
      whenOptions: whenOptionsDef.map(w => labelOption(i18n, w)),
      fromOptions: [{ value: 'end', label: 'accreditation.dateof.endvalidity' }].map(f => labelOption(i18n, f)),
    })
  }

  return ({
    whenOptions: whenOptionsDef.map(w => labelOption(i18n, w)),
    fromOptions: fromOptionsDef.map(f => labelOption(i18n, f)),
  })
}

export const triggerMessage = (i18n, { delayDays, delayWhen, delayFrom, slug }) => `
${delayDays} ${i18n('session.survey.days')}
${i18n(`session.survey.trigger.${delayWhen}`)}
${i18n(slug === MAIL_TEMPLATE_ACCREDITATION_LOST_ALERT ? 'accreditation.dateof.endvalidity' : `session.survey.trigger.${delayFrom}`)}`.toLocaleLowerCase()

export const formatMailAlias = sender => sender?.fromEmail && sender?.fromName
  ? `${sender.fromName} <${sender.fromEmail}>`
  : (sender?.fromEmail || null)
