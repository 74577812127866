import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'hooks/swr'
import { useI18n } from 'context/I18nProvider'
import { useQuery } from 'context/QueryProvider'
import Card from 'components/Card'
import Spinner from 'components/Spinner'
import { HeaderContext } from '../Header/context'

function Home() {
  const { i18n, i18name } = useI18n()
  const navigate = useNavigate()
  const { updateTree } = useContext(HeaderContext)
  const { client } = useQuery()

  // @TODO No hardcoded URL
  const { data: families, isValidating } = useSWR('/api/matrix/families?root=true', client.request)

  const goTo = (id, name) => {
    const url = `subfamily?id=${id}`
    updateTree({ url, name, id })
    navigate(url)
  }

  if (isValidating) {
    return (<Spinner />)
  }
  if (families && families.results && families.results.length) {
    return (
      <div className="families w-full flex justify-between">
        {families.results.map(family => (
          <Card
            className="flex flex-row flex-wrap justify-center"
            onClick={() => goTo(family.id, i18name(family))}
            key={family.id}
            name={i18name(family)}
            image={family.image?.url}
          />
        ))}
      </div>
    )
  }
  return (
    <p>{i18n('no_result')}</p>
  )
}

export default Home
