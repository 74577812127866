import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'

function ArrowBack() {
  const navigate = useNavigate()
  const { locale } = useI18n()

  return (
    <ArrowLeftOutlined
      className="text-xl font-bold absolute top-14 left-8 pt-2"
      onClick={() => navigate(`/${locale}/login`)} />
  )
}

export default ArrowBack
