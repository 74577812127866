/* eslint-disable react/prop-types, camelcase */
import React from 'react'
import { ConfigProvider } from 'antd'
import de_DE from 'antd/lib/locale/de_DE'
import en_GB from 'antd/lib/locale/en_GB'
import fr_FR from 'antd/lib/locale/fr_FR'
import 'antd/dist/reset.css'
import { useI18n } from 'context/I18nProvider'

const colorPrimary = '#009688' // green
const colorSecondary = '#673ab7' // purple

// @TODO Keep this for a demo for PO :)
// const colorPrimary = '#ef476f'
// const colorSecondary = '#118ab2'

// @TODO Theme colors management will happen here
export const customTheme = {
  colorPrimary,
  colorPrimaryLight: `${colorPrimary}b0`,
  colorSecondary,
  colorSecondaryLight: `${colorSecondary}b0`,
}

const gray = 'rgb(102,102,102)'
const lightGray = 'rgb(209,213,219)'

function MyConfigProvider({ children }) {
  const { locale } = useI18n()
  const locales = {
    de: de_DE,
    en: en_GB,
    fr: fr_FR,
  }

  React.useEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = `
        :root {
          --primary: ${customTheme.colorPrimary};
          --primaryLight: ${customTheme.colorPrimaryLight};
          --secondary: ${customTheme.colorSecondary};
          --secondaryLight: ${customTheme.colorSecondaryLight};
        }
    `
    document.head.appendChild(style)

    return () => document.head.removeChild(style)
  }, [])

  return (
    <ConfigProvider
      locale={locales[locale]}
      theme={{
        components: {
          Input: {
            activeBorderColor: lightGray,
            hoverBorderColor: lightGray,
          },
          DatePicker: {
            activeBorderColor: lightGray,
            hoverBorderColor: lightGray,
          },
          Table: {
            cellFontSize: '.9rem',
            cellPaddingBlock: 6,
            cellPaddingInline: 6,
            rowSelectedBg: `${customTheme.colorSecondary}23`,
            rowSelectedHoverBg: `${customTheme.colorSecondary}38`,
            selectionColumnWidth: 52,
          },
          Tabs: {
            itemColor: gray,
            itemActiveColor: customTheme.colorSecondary,
            itemHoverColor: gray,
            itemSelectedColor: customTheme.colorSecondary,
            inkBarColor: customTheme.colorSecondary,
          },
          Tooltip: {
            zIndexPopup: 2000,
          },
          Notification: {
            width: 420,
          },
        },
        token: {
          colorPrimary: customTheme.colorPrimary,
          colorLink: customTheme.colorPrimary,
          colorLinkActive: customTheme.colorPrimary,
          colorLinkHover: `${customTheme.colorPrimary}85`,
          colorTextTertiary: 'rgba(0, 0, 0, 0.65)',
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}

export default MyConfigProvider
