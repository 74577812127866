import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './styles.scss'

const UserIcon = ({ enabled, internal }) => (
  <span
    className={cx('eleo-user-icon', {
      active: enabled,
      inactive: !enabled,
      internal,
      external: !internal,
    })}
  />
)

UserIcon.defaultProps = {
  enabled: true,
  internal: true,
}

UserIcon.propTypes = {
  enabled: PropTypes.bool,
  internal: PropTypes.bool,
}

export default UserIcon
