import * as React from 'react'
import { Popconfirm } from 'antd'
import { useQuery } from 'context/QueryProvider'
import { useNotification } from 'context/NotificationProvider'
import { useI18n } from 'context/I18nProvider'
import { useBlob } from 'hooks/binary'
import { iriToId } from 'services/apiPlatform'
import DocumentTag from 'components/UI/DocumentTag'
import { API_DOCUMENT, API_DOCUMENTS_WISH } from 'constants/api-v2'

interface TrainingWish {
  id: number
  '@id': string
}

interface Document {
  '@id': string
  '@type': string
  name: string
  fileName: string
  originalName: string
  url: string
  extension: string
  updatedAt: string
  readonly: boolean
  trainingWish: TrainingWish
}
interface UserDocumentProps {
  callback: Function
  handleFileSelect: Function
  selectedFiles: []
}

export default function DocumentWishTag({ callback, ...props }: Document & UserDocumentProps) {
  const { client } = useQuery()
  const { i18n } = useI18n()
  const { notifyError } = useNotification()
  const { downloadBlob } = useBlob()

  // props cleanup
  const {
    fileName,
    originalName,
    '@type': unused,
    updatedAt,
    url,
    handleFileSelect,
    selectedFiles,
    readonly,
    trainingWish,
    ...rest
  } = props

  const [open, setOpen] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)


  const handleDownload = () => {
    downloadBlob(url)
      .catch((e: Error) => notifyError(e))
  }

  const handleDelete = (iri: string) => {
    const urlToDelete = trainingWish ? `${API_DOCUMENTS_WISH}/${iriToId(iri)}?trainingWishes[]=${trainingWish.id}`
      : `${API_DOCUMENT}/${iriToId(iri)}}`
    setLoading(true)
    client.delete(urlToDelete)
      .catch((e: Error) => notifyError(e))
      .finally(() => {
        const updatedFiles = selectedFiles.filter(file => file['@id'] !== props['@id'])
        handleFileSelect(updatedFiles)
        setLoading(false)
        callback()
      })
  }

  return (
    <Popconfirm
      title={i18n('label.attention')}
      description={i18n('label.document-delete-confirm')}
      open={open}
      onConfirm={() => handleDelete(props['@id'])}
      onCancel={() => setOpen(false)}
      okButtonProps={{ loading }}
    >
      <DocumentTag
        className="mb-2 cursor-pointer"
        {...rest}
        handleClick={() => handleDownload()}
        onClose={(e: any) => {
          e.preventDefault()
          setOpen(true)
        }}
        closable={!readonly}
      />
    </Popconfirm>
  )
}
