import * as React from 'react'
import { Card, Space } from 'antd'
import type { CardProps } from 'antd/lib'
import { RightOutlined } from '@ant-design/icons'

interface ContentProps {
  title: string
  icon: React.ReactElement
  children: React.ReactElement
}

export function TopContent({ title, icon, children, ...props }: ContentProps & CardProps) {
  return (
    <Card
      style={{ background: 'transparent' }}
      headStyle={{ background: '#ffffff40', color: 'white', borderBottom: 'none', marginBottom: 0 }}
      bodyStyle={{ background: '#ffffff40', paddingTop: 0 }}
      title={<Space size="middle">{icon}{title}</Space>}
      bordered={false}
      {...props}
    >
      {children}
    </Card>
  )
}

export function TopCard({ ...props }: CardProps) {
  return (
    <Card
      headStyle={{
        color: '#9575cd',
        background: 'linear-gradient(90deg, rgba(240,240,240,1) 0%, rgba(228,219,240,1) 100%)',
      }}
      bodyStyle={{ padding: '12px' }}
      {...props}
    />
  )
}

export function Content({ title, icon, children, ...props }: ContentProps & CardProps) {
  return (
    <Card
      style={{ background: 'transparent' }}
      headStyle={{ background: '#ffffff40', color: 'white' }}
      bodyStyle={{ background: 'white', display: 'flex', flexDirection: 'column', height: '100%' }}
      title={<Space size="middle">{icon}{title}</Space>}
      bordered={false}
      {...props}
    >
      {children}
    </Card>
  )
}

export function Separator() {
  return (
    <RightOutlined className="text-white" style={{ fontSize: '2rem' }} />
  )
}
