import * as React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'

export default function Notification({ type = 'info', ...props }) {
  return (
    <Alert
      type={type}
      showIcon
      closable
      {...props}
    />
  )
}

Notification.propTypes = {
  type: PropTypes.string,
}
