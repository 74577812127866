import * as React from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'context/I18nProvider'
import { useQuery } from 'context/QueryProvider'
import { API_URL_TRAINING_WISHES } from 'constants/api'
import { useNotification } from 'context/NotificationProvider'
import Modal from 'components/UI/Modal'
import AsyncSelect from 'components/UI/AsyncSelect'
import { API_USERS } from 'constants/api-v2'
import { formatUser } from 'services/users'

import './styles.scss'

function UpdateManager({ id, onClose, doneAction }) {
  const { i18n } = useI18n()
  const { notifyError } = useNotification()
  const { client } = useQuery()

  const [user, setUser] = React.useState()

  const handleOk = () => {
    client.patch(`${API_URL_TRAINING_WISHES}/${id}`, { manager: user.value })
      .then(() => {
        doneAction()
        onClose()
      })
      .catch(e => notifyError(e))
  }

  return (
    <Modal
      title={i18n('trainingPlan.updateManager.modal.title')}
      width="1000px"
      onOk={handleOk}
      onCancel={onClose}
      confirmDisabled={!user}
    >
      <div className="update-manager">
        <div className="body">
          <AsyncSelect
            url={`${API_USERS}?profileAssignments.profile.type=manager&internal=true&enabled=true`}
            mapper={option => ({ ...option, value: option.id, label: formatUser(option) })}
            loadingMessage={() => i18n('global_searching')}
            noOptionsMessage={() => i18n('global_no_options')}
            onChange={setUser}
            value={user}
            defaultOptions
            cacheOptions
            insideModal
            apiPlatform
          />
        </div>
      </div>
    </Modal>
  )
}

UpdateManager.defaultProps = {
  doneAction: () => {},
}

UpdateManager.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  doneAction: PropTypes.func,
}

export default UpdateManager
