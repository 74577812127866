import React from 'react'
import { Spin as AntdSpin } from 'antd'
import type { SpinProps as AntdSpinProps } from 'antd'

interface SpinProps {
  className?: string
}

export default function Spin({ className, ...props }: SpinProps & AntdSpinProps) {
  return (
    <div className={`w-full flex justify-center items-center my-24 ${className || ''}`}
    >
      <AntdSpin size="large" {...props} />
    </div>
  )
}
