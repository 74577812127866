import React from 'react'
import { Tag } from 'antd'
import type { TagProps } from 'antd'
import FileTypeIcon from 'components/FileTypeIcon'

interface DocumentTagProps {
  '@id': string
  name: string
  extension: string
  // eslint-disable-next-line no-unused-vars
  handleClick: (e: any) => void
}

const getColor = (ext: string) => {
  const grey = '#666'
  const blue = '#00abff'
  const green = '#87d068'
  const orange = '#ff9a00'
  const purple = '#c900ff'
  const red = '#e02222'

  switch (ext) {
    case 'doc':
    case 'docx':
    case 'txt':
      return blue
    case 'xls':
    case 'xlsx':
      return green
    case 'png':
    case 'jpg':
    case 'jpeg':
      return orange
    case 'ppt':
    case 'pptx':
      return purple
    case 'pdf':
      return red
    default:
      return grey
  }
}

export default function DocumentTag(props: DocumentTagProps & TagProps) {
  const { '@id': id, extension, handleClick, name, ...rest } = props

  return (
    <Tag
      className="cursor-pointer"
      icon={<FileTypeIcon extension={extension} />}
      color={getColor(extension)}
      {...rest}
    >
      <span
        onClick={() => handleClick(id)}
        onKeyDown={() => handleClick(id)}
      >
        {name}
      </span>
    </Tag>
  )
}
