/* eslint-disable react/prop-types */
import React from 'react'

export function H1({ children }) {
  return <div className="w-full text-center text-3xl">{children}</div>
}

export function H2({ children }) {
  return <div className="w-full text-center text-lg">{children}</div>
}

export function Block({ children }) {
  return <div className="w-full flex items-center">{children}</div>
}

export function Center({ children }) {
  return <div className="w-full flex justify-center items-center">{children}</div>
}

export function ErrorText({ children }) {
  return <div className="w-full flex justify-center items-center text-red-600">{children}</div>
}

export function FormWrapper({ children }) {
  return (
    <div className="w-[420px] h-[420px] relative -top-12 bg-white flex rounded-[15px] mx-auto px-16 py-8">
      {children}
    </div>
  )
}

function Form({ onSubmit, children }) {
  return (
    <FormWrapper>
      <form
        className="h-full flex flex-row flex-wrap justify-center items-center space-between"
        onSubmit={onSubmit}
      >
        {children}
      </form>
    </FormWrapper>
  )
}

export default Form
