import React from 'react'
import PropTypes from 'prop-types'
import { SWRConfig as SWRConfigDefault } from 'swr'

export default function SWRConfig({ children }) {
  return (
    <SWRConfigDefault value={{
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }}>
      {children}
    </SWRConfigDefault>
  )
}

SWRConfig.propTypes = {
  children: PropTypes.element,
}
