import React from 'react'
import { NavLink } from 'react-router-dom'

interface MenuLinkProps {
  to: string
  children: React.ReactNode
  className: string
}

export default function MenuLink({ to, children, className, ...props }: MenuLinkProps) {
  if (to && to?.startsWith('http')) {
    return (
      <a href={to} className={className} {...props}>
        {children}
      </a>
    )
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }) => `${className} ${isActive ? 'bg-gray-200' : ''}`}
      {...props}
    >
      {({ isActive }) => (
        <>
          {isActive
            ? <div className="absolute w-[3px] h-[31px] mr-[1px] bg-[--primaryLight] left-0.5 top-1" />
            : null}
          {children}
        </>
      )}
    </NavLink>
  )
}
