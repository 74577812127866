import QueryClient from 'services/query'
import {
  API_URL_TRAINING_PLANS,
  API_URL_TRAINING_WISHES,
} from 'constants/api'
import { API_TRAININGS } from 'constants/api-v2'

interface LoaderParams {
  params: {
    id?: string
    wishId?: string
  }
}

export const loadTraining = (client: QueryClient, url: string) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let training = null
    try {
      training = await client.get(`${url}/${id}`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
      throw new Error(e)
    }

    return training
  }
)

export const loadTrainingAndWish = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id, wishId } = params

    let training = null
    let wish = null
    let trainingPlans = []

    try {
      [training, wish, trainingPlans] = await Promise.all([
        !!id ? client.get(`${API_TRAININGS}/${id}`) : Promise.resolve(null),
        !!wishId ? client.get(`${API_URL_TRAINING_WISHES}/${wishId}`) : Promise.resolve(null),
        !!id ? client.get(API_URL_TRAINING_PLANS) : Promise.resolve({ items: [] }),
      ])
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }

    return { training, wish, trainingPlans: trainingPlans.items }
  }
)
