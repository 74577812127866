import * as React from 'react'
import { useProfile } from 'context/ProfileProvider'

const SCRIPT = 'https://static.zdassets.com/ekr/snippet.js?key=1e55577c-1f55-4c09-a9d7-92a4868d01fc'
const ID = 'ze-snippet'

function Zendesk() {
  const { hasPermission } = useProfile()

  React.useEffect(() => {
    if (
      !hasPermission('ADMIN_CAN_SEE_ZENDESK')
      || document.getElementById(ID)
      || process.env.NODE_ENV !== 'production'
    ) return undefined

    const script = document.createElement('script')
    script.src = SCRIPT
    script.id = ID
    document.body.appendChild(script)

    return () => document.getElementById(ID).remove()
  }, [hasPermission])


  return <div />
}

export default Zendesk
