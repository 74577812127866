import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DeleteOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import { useNotification } from 'context/NotificationProvider'
import { useQuery } from 'context/QueryProvider'
import Button from 'components/UI/Button'
import Modal from 'components/UI/Modal'
import { API_URL_MATRIX_LINK } from 'constants/api'
import AddTrainingBlock from './AddTrainingBlock'

const parseData = (i18name, links = []) => {
  const list = links.map(d => ({
    name: i18name(d.training),
    code: d.training.code,
    id: d.training.id,
    linkId: d.id,
  }))
  const title = links.length ? i18name(links[0].skill) : ''
  return {
    list, title,
  }
}

function MatrixModal({ onClose, data, onChange, navigate }) {
  const { i18n, i18name, locale } = useI18n()
  const { notifyError } = useNotification()
  const { client } = useQuery()

  const { links, edit, coordinates } = data
  const { title, list } = parseData(i18name, links)
  const [dirty, setDirty] = useState(false)
  const deleteLink = async linkId => {
    try {
      setDirty(true)
      await client.request(`${API_URL_MATRIX_LINK}/${linkId}`, { method: 'DELETE' })
      onChange()
    } catch (e) {
      notifyError(e)
    }
  }

  useEffect(() => setDirty(false), [data])

  return (
    <Modal
      onCancel={onClose}
      title={title ? `${i18n('modules_for')} ${title}` : i18n('label.module')}
      width="800px"
      footer={null}
    >
      <div className="modal-list">
        {list.map(d => (
          <div key={`${d.name}-${d.code}`} className="list-item justify-between align-items-center">
            <div className="item left-part">
              <span>{d.name}</span>
              <span>{d.code}</span>
            </div>
            <div className="item right-part">
              {
                edit
                  ? (
                    <DeleteOutlined
                      onClick={() => { if (!dirty) deleteLink(d.linkId) }}
                      className="text-[--primary]"
                    />
                  ) : (
                    <Button ghost onClick={() => navigate(`/${locale}/catalog/training/${d.id}`)}>
                      {i18n('access')}
                    </Button>
                  )
              }
            </div>
          </div>
        ))}
        {edit && <AddTrainingBlock coordinates={coordinates} onAdd={onChange} trainings={list.map(d => d.id)} /> }
      </div>
    </Modal>
  )
}

MatrixModal.propTypes = {
  data: PropTypes.shape({
    links: PropTypes.array.isRequired,
    edit: PropTypes.bool.isRequired,
    coordinates: PropTypes.shape({
      jobId: PropTypes.number,
      skillId: PropTypes.number,
      levelId: PropTypes.number,
    }),
  }),
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

export default MatrixModal
