import { LANG_ISO } from 'utils/lang'

export const numberWithCommas = x => x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''

// @see https://stackoverflow.com/a/11832950
export const roundNum = num => Math.round((num + Number.EPSILON) * 100) / 100

export const typeNumberInput = value => {
  if (typeof value === 'number') return value
  if (typeof value === 'string') {
    return Number(value.replace(',', '.'))
  }
  return value
}

export const toCurrency = (value, locale, currency = 'EUR') => (
  new Intl.NumberFormat(
    LANG_ISO[locale],
    {
      currency,
      style: 'currency',
    },
  ).format(value)
)
