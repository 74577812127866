import * as React from 'react'
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Alert } from 'antd'
import { useI18n } from 'context/I18nProvider'
import { useQuery } from 'context/QueryProvider'
import { selectModalProps } from 'utils/select'

function InfiniteSelect({
  url,
  mapper,
  insideModal,
  proxy,
  prependOptions,
  selectAll,
  selectAllValue,
  onChange,
  valueOverwrite,
  defaultValue,
  apiPlatform,
  isMulti,
  ...props
}) {
  const { i18n, i18name } = useI18n()
  const { client } = useQuery()
  const selectRef = React.useRef(null)
  const [inputOptions, setInputOptions] = React.useState([])
  const [error, setError] = React.useState(false)

  const [value, setValue] = React.useState()
  const mapOption = val => val ? { value: val.id, label: val.label || i18name(val) } : null

  const loadOptions = async (search, loadedOptions, { page }) => {
    const separator = url.includes('?') ? '&' : '?'
    const targetUrl = `${url}${separator}q=${encodeURIComponent(search)}&page=${page || 1}`
    let resp
    let data
    try {
      resp = await client.request(targetUrl)
      const fromProxy = !!proxy ? proxy(targetUrl, { data: resp }) : { data: resp }
      data = fromProxy?.data
    } catch {
      // Return early if API fails
      setError(true)
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      }
    }

    // Support for legacy endpoints and APIP
    let options
    let hasMore

    if (apiPlatform) {
      options = data['hydra:member']
      hasMore = 'hydra:next' in data['hydra:view']
    } else {
      options = 'results' in data ? data?.results : data
      hasMore = Boolean(data?.hasNextPage)
    }
    options = options.map(mapper || mapOption)

    if (prependOptions) {
      options = [...prependOptions, ...options]
      setInputOptions([...inputOptions, { search, options }])
    }

    // Prepend a Select All "button/option"
    if (selectAll && options.length > 1) {
      options = [{ value: selectAllValue, label: i18n('select_all') }, ...options]
      setInputOptions([...inputOptions, { search, options }])
    }

    return {
      options,
      hasMore,
      additional: { page: page + 1 },
    }
  }

  const handleOnChange = val => {
    let values = val

    if (isMulti) {
      // Closes menu when selecting "All options"
      if (selectAll && values?.filter(o => o.value === selectAllValue).length) {
        const selectObj = selectRef.current
        if (selectObj) selectObj.blur()
      }

      // Select all options "magically"
      if (selectAll && values.filter(o => o.value === selectAllValue).length) {
        const inputSearch = selectRef.current.props.inputValue
        values = inputOptions.find(i => i.search === inputSearch)?.options
      }

      // Do not pass the SelectAll option if it's there
      if (selectAll) values = values.filter(v => v.value !== selectAllValue)
    }

    setValue(values)
    onChange(values)
  }

  const valueProps = defaultValue ? { defaultValue } : { value: valueOverwrite || value }

  if (error) return <Alert type="error" message={i18n('internal.error')} showIcon />

  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      debounceTimeout={500}
      additional={{ page: 1 }}
      onChange={handleOnChange}
      closeMenuOnSelect={props.closeMenuOnSelect || !props.isMulti}
      selectRef={selectRef}
      isMulti={isMulti}
      {...(insideModal ? selectModalProps : {})}
      {...valueProps}
      {...props}
    />
  )
}

InfiniteSelect.defaultProps = {
  mapper: item => item,
  insideModal: false,
  keepInputValue: false,
  onChange: () => {},
  selectAllValue: '',
}

InfiniteSelect.propTypes = {
  url: PropTypes.string.isRequired,
  mapper: PropTypes.func,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  insideModal: PropTypes.bool,
  keepInputValue: PropTypes.bool,
  proxy: PropTypes.func,
  prependOptions: PropTypes.array,
  selectAll: PropTypes.bool,
  selectAllValue: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  valueOverwrite: PropTypes.array,
  defaultValue: PropTypes.any,
  apiPlatform: PropTypes.bool,
}

export default InfiniteSelect
