import * as React from 'react'
import { CheckOutlined, MoreOutlined } from '@ant-design/icons'
import type { Auth } from 'context/types'
import { Popover } from 'antd'
import type { Notification, PagedCollection } from 'shared/types'
import { useAuth } from 'context/AuthProvider'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import { useQuery } from 'context/QueryProvider'
import { useMercure, useMercureData } from 'hooks/mercure'
import useSWR from 'hooks/swr'
import NotificationItem from 'components/Notification/Item'
import Button from 'components/UI/Button'
import Dropdown from 'components/UI/Dropdown'
import Spinner from 'components/Spinner'
import { API_NOTIFICATIONS, API_NOTIFICATIONS_COUNT, API_NOTIFICATIONS_MARK_ALL_AS_READ } from 'constants/api-v2'
import { isPagedCollection } from 'services/apiPlatform'
import { urlAddParams } from 'services/url'
import NotificationIcon from './Icon'

type MercureData = {
  type: string
}

type NotificationCount = {
  count: number
}

export default function NotificationHeader() {
  const { profile } = useProfile()
  const { user } = useAuth() as Auth
  const { i18n } = useI18n()
  const { client } = useQuery()
  const { subscribe } = useMercure()

  const [notificationsCount, setNotificationsCount] = React.useState<number>(0)
  const [opened, setOpened] = React.useState<boolean>(false)

  useSWR(urlAddParams(API_NOTIFICATIONS_COUNT, { view: 'unread' }), {
    onSuccess: (data: NotificationCount) => setNotificationsCount(data.count),
  })

  const entityChannels = [
    '/users',
    `/users/${user.id}`,
  ]
  const notificationList = urlAddParams(API_NOTIFICATIONS, { view: 'unread', itemsPerPage: 10 })
  const { data: notificationData, mutate, isValidating }: {
    data: undefined | PagedCollection<Notification>
    mutate: Function
    isValidating: boolean
  } = useSWR(opened ? notificationList : null, {
    onSuccess: (d: PagedCollection<Notification>) => setNotificationsResponse(d),
  })
  const [
    notificationsResponse,
    setNotificationsResponse,
  ] = React.useState<undefined | PagedCollection<Notification>>(notificationData)
  const notifications = useMercureData<undefined | PagedCollection<Notification>>(entityChannels, notificationsResponse)

  React.useEffect(() => {
    const channels = [
      '/users',
      `/users/${user.id}`,
      `/profiles/${profile.id}`,
    ]

    const topics = [
      '/notifications',
    ]

    const messageEventSource = subscribe(channels, topics, ({ data }: { data: MercureData }) => {
      switch (data.type) {
        case 'new_notification':
          mutate()
          setNotificationsCount(count => count + 1)
          break
        default:
          break
      }
    })

    return () => messageEventSource?.close()
  }, [subscribe, profile.id, user.id, mutate])

  const onUpdated = (notification: Notification) => {
    setNotificationsCount(count => Math.max(0, count + (notification.read ? -1 : 1)))
    setNotificationsResponse(prevState => {
      if (prevState && isPagedCollection<Notification>(prevState) && prevState['hydra:member']) {
        const index = prevState['hydra:member'].findIndex((item: Notification) => item.id === notification.id)
        if (index !== -1) {
          return {
            ...prevState,
            'hydra:member': [
              ...prevState['hydra:member'].slice(0, index),
              notification,
              ...prevState['hydra:member'].slice(index + 1),
            ],
          }
        }
      }

      return prevState
    })
  }

  const items = React.useMemo((): React.ReactNode => {
    const actions = [
      {
        key: 'mark_all_as_read',
        icon: <CheckOutlined />,
        label: i18n('action.mark_all_as_read'),
        onClick: async () => {
          await client.patch(API_NOTIFICATIONS_MARK_ALL_AS_READ, {})
          setNotificationsCount(0)
          setNotificationsResponse(prevState => {
            if (isPagedCollection<Notification>(prevState) && prevState && prevState['hydra:member']) {
              return {
                ...prevState,
                'hydra:member': prevState['hydra:member'].map((item: Notification) => ({
                  ...item,
                  read: true,
                })),
              }
            }

            return prevState
          })
        },
      },
    ]

    return (
      <div className="-m-[12px] pb-2">
        <div className="flex p-4 justify-between items-center border-0 border-b border-solid border-b-gray-200">
          <h3 className="text-3xl font-semibold mb-0">{i18n('notifications.title')}</h3>
          <Dropdown
            items={actions}
            trigger={['click']}
            arrow
            className="px-2"
          >
            <Button
              type="text"
              className="px-2"
              onClick={e => e.stopPropagation()}
            >
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
        {isValidating && (
          <div className="flex flex-col items-center justify-center p-4">
            <Spinner />
          </div>
        )}
        {!isValidating && notifications && notifications['hydra:member'] && !notifications['hydra:member'].length && (
          <div className="flex flex-col items-center justify-center p-4">
            <p className="text-lg text-gray-800 mb-0">{i18n('notification.no_unread')}</p>
          </div>
        )}
        {!isValidating
              && notifications
              && notifications['hydra:member']
              && (
                <div className="max-h-[48rem] overflow-y-auto">
                  {notifications['hydra:member'].map(notification => (
                    <React.Fragment key={notification.id}>
                      <NotificationItem
                        notification={notification}
                        onUpdate={onUpdated}
                      />
                    </React.Fragment>
                  ))}
                </div>
              )}
      </div>
    )
  }, [i18n, isValidating, notifications, client])

  return (
    <Popover
      content={items}
      placement="bottom"
      arrow
      onOpenChange={() => setOpened(true)}
      overlayClassName="max-w-full w-128"
      trigger="click"
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" onClick={e => e.preventDefault()}>
        <NotificationIcon
          count={notificationsCount}
          className="text-white text-2xl"
          badgeClassName="w-[24px]"
        />
      </a>
    </Popover>
  )
}
