import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InfoCircleOutlined } from '@ant-design/icons'
import useSWR from 'hooks/swr'
import { useI18n } from 'context/I18nProvider'
import Modal from 'components/UI/Modal'
import Switch from 'components/UI/Switch'
import AsyncSelect from 'components/UI/AsyncSelect'
import { useNotification } from 'context/NotificationProvider'
import { useQuery } from 'context/QueryProvider'
import { API_URL_TRAINING_PLAN_UPDATE_TRAINING } from 'constants/api'
import { API_TRIGGER_EMAIL_DEFINITIONS, API_TRAININGS } from 'constants/api-v2'
import { FREE_TRAINING_WISH_LINK_TRAINING as email } from 'utils/email'

function UpdateTraining({ title, id, isFictive, onClose, doneAction }) {
  const { i18n, i18name } = useI18n()
  const { notifyError } = useNotification()
  const { client } = useQuery()

  const [newTraining, setNewTraining] = useState(null)
  const [sendConfirmation, setSendConfirmation] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const updateFictiveTrainingTrigger = `${API_TRIGGER_EMAIL_DEFINITIONS}/${email}`
  const { data: triggerMailDef } = useSWR(updateFictiveTrainingTrigger)

  const submitable = newTraining !== null

  const updateTraining = async () => {
    try {
      setSubmitted(true)
      await client.post(API_URL_TRAINING_PLAN_UPDATE_TRAINING.replace('ID', id), {
        training: newTraining,
        sendConfirmation,
      })
      doneAction()
      onClose()
    } catch (e) {
      notifyError(e)
      setSubmitted(false)
    }
  }

  const modalTitle = i18n('trainingplan.updatetraining.modal.title')

  return (
    <Modal
      title={modalTitle}
      width="1000px"
      okButtonProps={{ disabled: submitted || !submitable }}
      onOk={updateTraining}
      onCancel={onClose}
    >
      <div className="update-training flex flex-wrap w-full py-8">
        <div className="w-full mb-8">{`${i18n('label.currentformation')} ${title}`}</div>
        <AsyncSelect
          onChange={item => setNewTraining(item ? item.value : null)}
          url={`${API_TRAININGS}?status[]=validated&pathway=false`}
          mapper={item => ({
            ...item,
            label: `${i18name(item)} (${item.code})`,
            value: item.id,
          })}
          className="mx-auto"
          insideModal
          apiPlatform
        />
        {isFictive && !!triggerMailDef?.enabled && (
          <div className="mt-12">
            <p className="mb-4">{i18n('needs.validation.free_training_wish_confirmation_email')}</p>
            <Switch
              className="w-24"
              checked={Boolean(sendConfirmation)}
              onChange={setSendConfirmation}
              labelOff={i18n('label.no')}
              labelOn={i18n('label.yes')}
            />
          </div>
        )}
        {isFictive && (
          <div className="w-full mt-4">
            <InfoCircleOutlined
              className="w-[24px] h-[24px] text-[#12cb81] text-[1.15rem]"
            />
            {i18n('trainingplan.updatetraining.modal.fictive_deletion_info')}
          </div>
        )}
      </div>
    </Modal>
  )
}

UpdateTraining.defaultProps = {
  doneAction: () => {},
}

UpdateTraining.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isFictive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  doneAction: PropTypes.func,
}

export default UpdateTraining
