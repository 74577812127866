import React from 'react'
import './styles.scss'

function Spinner() {
  return (
    <div data-testid="spinner" className="spinner">
      <div className="cube1"></div>
      <div className="cube2"></div>
    </div>
  )
}

export default Spinner
