import React, { useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useSWR from 'hooks/swr'
import { useI18n } from 'context/I18nProvider'
import Card from 'components/Card'
import Spinner from 'components/Spinner'
import { HeaderContext } from '../Header/context'

const SkillTypes = () => {
  const { i18n, i18name, locale } = useI18n()
  const location = useLocation()
  const navigate = useNavigate()
  const { updateTree } = useContext(HeaderContext)
  const params = new URLSearchParams(location.search)
  const skillId = params.get('id')

  const { data: skill, isValidating } = useSWR(`/api/matrix/skill-types?job=${skillId}`)

  function goToGraph(skillTypeId, name) {
    const url = `/${locale}/catalog/matrix/graph?id=${skillId}&skillType=${skillTypeId}`
    updateTree({ name, url, id: skillTypeId })
    navigate(url)
  }

  if (isValidating) {
    return (<Spinner />)
  }

  if (skill && skill.results && skill.results.length) {
    return (
      <div className="families w-full flex justify-between">
        {skill.results.map(skillType => (
          <Card
            onClick={() => goToGraph(skillType.id, i18name(skillType))}
            key={skillType.id}
            name={i18name(skillType)}
            image={skillType.image?.url}
          />
        ))}
      </div>
    )
  }
  return (
    <p>{i18n('no_result')}</p>
  )
}

export default SkillTypes
