import * as React from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import { useI18n } from 'context/I18nProvider'

// This is only exported for tests
export function messageFromError(err) {
  if (typeof err === 'string') {
    return err
  }
  const responseCode = err?.response?.data?.code ?? err?.response?.status
  const responseMsg = err?.response?.data?.message
      ?? err?.response?.data?.detail
      ?? err?.response?.data?.['hydra:description']
  return (responseCode >= 400 && responseCode < 500 && !!responseMsg)
    ? responseMsg
    : 'internal.error'
}

const NotificationContext = React.createContext()

export default function NotificationContextProvider({ children }) {
  const [messageApi, contextHolder] = message.useMessage()
  const { i18n } = useI18n()

  const notifySuccess = React.useCallback(txt => {
    const content = (typeof txt === 'object' || !txt)
      ? i18n('notification.generic.success')
      : txt
    return messageApi.open({
      type: 'success',
      content,
    })
  }, [i18n, messageApi])

  const notifyInfo = React.useCallback(txt => {
    const content = (typeof txt === 'object' || !txt)
      ? ' '
      : txt
    return messageApi.info(content)
  }, [messageApi])

  const notifyError = React.useCallback(e => {
    const content = i18n(messageFromError(e))
    return messageApi.open({
      type: 'error',
      content,
    })
  }, [i18n, messageApi])

  const value = React.useMemo(() => ({
    notifyError,
    notifyInfo,
    notifySuccess,
  }), [notifyError, notifyInfo, notifySuccess])

  return (
    <NotificationContext.Provider value={value}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  )
}

NotificationContextProvider.propTypes = {
  children: PropTypes.node,
}

export function useNotification() {
  const context = React.useContext(NotificationContext)
  if (context === undefined) {
    throw new Error('NotificationContext must be used within a Provider')
  }
  return context
}

NotificationContextProvider.propTypes = {
  children: PropTypes.node,
}
