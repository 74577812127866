import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import type { LinkProps as RouterLinkProps } from 'react-router-dom'

interface LinkProps {
  icon?: React.ReactElement
  type?: string
  className?: string
}

export default function Link({ children, icon, className, type, ...props }: RouterLinkProps & LinkProps) {
  let css = (type === 'text') ? 'text-gray-600' : 'text-[--primary]'

  if (!!icon && children) css += ' space-x-2'
  if (!!className) css += ` ${className}`

  return (
    <RouterLink className={css} {...props}>
      {icon && icon}
      <span>{children}</span>
    </RouterLink>
  )
}
