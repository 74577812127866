import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useLocale } from 'hooks/locale'

function Redirect({ path }) {
  const { locale } = useLocale()

  if (!path) throw new Error('Redirect.path is mandatory')

  if (!locale) return null

  return <Navigate to={`/${locale}/${path}`} replace />
}

Redirect.propTypes = {
  path: PropTypes.string.isRequired,
}

export default Redirect
