export const PROFILE_ACCREDITATION_BOARD = {
  MANAGER: 'manager',
  EMPLOYEE: 'employee',
}

export const PROFILE_RENEWABLE_TRAINING_BOARD = {
  MANAGER: 'manager',
  EMPLOYEE: 'employee',
}

export const PROFILE_NEEDS = {
  MANAGER: 'manager',
  EMPLOYEE: 'employee',
}

export const PROFILE_TRAINING_PLAN = {
  ADVANCED: 'advanced',
  BASIC: 'basic',
}

export const PROFILE_WISH_DETAILS = {
  ADMIN: 'training_service',
  MANAGER: 'manager',
}
