interface Values {
  [key: string]: any
}

export function encodedURL() {
  // @ts-ignore
  return new URL(window.location).toString().replace('?', '%3F')
}

const updateSearchParams = (params: Values, searchParams: URLSearchParams) => {
  if (params && Object.keys(params).length) {
    Object.keys(params).forEach((key: string) => {
      const value = params[key]

      /**
       * a param value can be `false`
       * ...url&internal=false
       */
      if ((value || value === false) && value !== 'undefined') {
        if (Array.isArray(value)) {
          if (value.length) {
            value.forEach(v => {
              searchParams.append(key.includes('[]') ? key : `${key}[]`, v)
            })
          } else {
            searchParams.delete(key)
          }
        } else {
          // @ts-ignore
          searchParams.set(key, [value])
        }
      } else searchParams.delete(key)
    })
  }
}

export const urlAddParams = (urlRaw: string, vals: object) => {
  const loc = window.location
  const full = `${loc.protocol}//${loc.hostname}:${loc.port || ''}`
  const url = new URL(urlRaw, full)
  const searchParams = new URLSearchParams(url.search)

  updateSearchParams(vals, searchParams)
  let baseUrl = `${url.origin}${url.pathname}`
  const params = searchParams.toString()

  if (params) baseUrl += `?${params}`

  return new URL(baseUrl).toString()
}
