import * as React from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useModal } from 'react-modal-hook'
import dayjs from 'dayjs'
import { EyeOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import Details from 'components/TrainingWish/Details'

interface Item {
  id: number
  username: string
  creationDate: string
  vintage: string
  status: string
}
interface SubWishesProps {
  wishes: {
    results: any[] // @TODO Fix when porting to APIP
    nbResults: number // @TODO Fix when porting to APIP
  }
  pagination: {
    current: number
    pageSize: number
    handleChange: () => void
  }
}

export default function SubWishes({ wishes, pagination }: SubWishesProps) {
  const { i18n } = useI18n()
  const [selected, setSelected] = React.useState<number>()

  const { handleChange, ...rest } = pagination

  const [showDetails, hideDetails] = useModal(() => (
    !!selected ? <Details id={selected} onClose={hideDetails} /> : null
  ), [selected])

  const showModal = React.useCallback((id: number) => {
    setSelected(id)
    showDetails()
  }, [showDetails])

  const columns: ColumnsType<Item> = [
    {
      title: i18n('label.beneficiary'),
      dataIndex: 'username',
      width: '25%',
    }, {
      title: i18n('label.date'),
      dataIndex: 'creationDate',
      width: '20%',
      align: 'center' as const,
      render: value => dayjs(value).format('DD/MM/YYYY'),
    }, {
      title: i18n('label.vintage'),
      dataIndex: 'vintage',
      width: '20%',
      align: 'center' as const,
    }, {
      title: i18n('label.status'),
      dataIndex: 'status',
      width: '20%',
    }, {
      title: '',
      dataIndex: 'id',
      width: '15%',
      align: 'center' as const,
      render: value => (
        <EyeOutlined className="ap-eye" onClick={() => showModal(value)} />
      ),
    },
  ]

  const { results } = wishes || {}

  return (
    <div className="w-full">
      {wishes?.results?.length ? (
        <Table
          rowKey="id"
          tableLayout="fixed"
          dataSource={results}
          columns={columns}
          pagination={rest}
          onChange={handleChange}
        />
      ) : (
        <div className="w-full flex justify-center text-lg font-lg italic">
          {i18n('assign_provision_emptytable')}
        </div>
      )}
    </div>
  )
}
