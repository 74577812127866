/* eslint-disable react/prop-types */
import * as React from 'react'
import { ModalProvider } from 'react-modal-hook'
import ConfigProvider from 'context/ConfigProvider'
import NotificationProvider from 'context/NotificationProvider'

export default function PublicContainer({ children }) {
  return (
    <ConfigProvider>
      <NotificationProvider>
        <ModalProvider>{children}</ModalProvider>
      </NotificationProvider>
    </ConfigProvider>
  )
}
