import { roundNum } from 'utils/number'

/**
 * "1:15" -> 1.25
 * "1:30" -> 1.5
 */
export const timeToNum = (hours, minutes = 0) => {
  const m = minutes % 60
  const h = hours + Math.floor(minutes / 60)
  const total = h + parseInt(m, 10) / 60

  return roundNum(total)
}

export const dateToString = date => date.toISOString().split('T')[0]

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export const personName = (lastName, firstName) => {
  const last = (lastName || '').toUpperCase()
  const first = (firstName || '').toLowerCase().replace(/^.?|[ -](.)/g, firstLetter => firstLetter.toUpperCase())
  return (`${last} ${first}`).trim()
}

const noop = v => v
/**
 * Given 'Hello {person1} and {person2}'
 *   And { person1: 'Jhon', person2: 'Jack'})
 * Then : 'Hello Jhon and Jack'
 *
 * options.replace: optional callback to manage replacement
 */
export const messageContext = (sentence, context, options) => {
  if (!context) return sentence
  const replace = options?.replace || noop
  return Object.entries(context)
    .reduce(
      (result, [key, value]) => result
        .replace(new RegExp(`{${key}}`, 'g'), replace(value))
        .replace(new RegExp(`${key}`, 'g'), replace(value)),
      sentence || '',
    )
}
