import * as React from 'react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'

export default function FiltersWrapper({ children }: { children: React.ReactElement }) {
  const { i18n } = useI18n()
  const [open, setOpen] = React.useState(false)
  const Icon = open ? CaretUpOutlined : CaretDownOutlined
  return (
    <div className={`col-start-4 ${open ? 'row-start-1 row-end-4' : ''}`}>
      <div className={`catalog-filters bg-white rounded-xl px-4 py-6 ${open ? 'max-h-[1000px]' : 'max-h-[90px]'}`}>
        <div className="flex flex-nowrap space-between">
          <div className="text-gray-600 text-sm pr-2 mb-4 w-4/5 flex flex-col">
            <span className="uppercase">{i18n('label.filters')}</span>
            <span>{i18n('catalog.refine.text')}</span>
          </div>
          <Icon
            className={`
              text-xl text-white bg-[--primary] flex justify-center items-center w-1/5 basis-[40px] h-[40px]
              rounded-full drop-shadow-md cursor-pointer
            `}
            onClick={() => setOpen(prev => !prev)}
          />
        </div>
        <div>{ open ? children : null }</div>
      </div>
    </div>
  )
}
