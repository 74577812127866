import * as React from 'react'
import PropTypes from 'prop-types'
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom'
import ErrorPage from 'components/UI/ErrorPage'

function Routes({ children }) {
  return (
    <ReactRouterRoutes>
      <Route errorElement={<ErrorPage />}>
        {children}
      </Route>
    </ReactRouterRoutes>
  )
}

Routes.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Routes
