import * as React from 'react'
import { useRouteLoaderData } from 'react-router-dom'
import type { Item, PagedCollection } from 'shared/types'
import { isItem, isPagedCollection } from 'services/apiPlatform'

interface Settings {
  mercure: {
    hubUrl: string
    topicUrl: string
  }
}

/**
 * Channels are the topic prefix to subscribe to.
 * Usage:
 *   const { subscribe } = useMercure()
 *   const eventSource = subscribe(
 *     ['/users', '/users/1'],
 *     ['deploy', 'notifications'],
 *     ({ data }) => {
 *       console.log(data.type)
 *     }
 *   )
 *   // ...
 *   eventSource.close()
 * Generated topic uris will be (urlencoded): https://ws.eleo.io/.well-known/mercure?topic=1&topic=2&topic=3&topic=4
 *  1. https://<customer>.eleo.io/users?topic=https://<customer>.eleo.io/deploy
 *  2. https://<customer>.eleo.io/users/1?topic=https://<customer>.eleo.io/deploy
 *  3. https://<customer>.eleo.io/users?topic=https://<customer>.eleo.io/notifications
 *  4. https://<customer>.eleo.io/users/1?topic=https://<customer>.eleo.io/notifications
 */
export const useMercure = () => {
  const settings = useRouteLoaderData('root') as Settings
  const { mercure } = settings

  const createEventSource = React.useCallback((channels: string[], topics: string[]) => {
    const url = new URL(mercure.hubUrl)

    // Subscribe to topics directly
    channels.forEach(channel => {
      topics.forEach(topic => {
        url.searchParams.append(
          'topic',
          `${mercure.topicUrl}${channel}?topic=${encodeURIComponent(`${mercure.topicUrl}${topic}`)}`,
        )
      })
    })

    return new EventSource(url, { withCredentials: true })
  }, [mercure])

  const subscribe = React.useCallback((channels: string[], topics: string[], callback: Function) => {
    if (!mercure?.hubUrl) return null // avoid infinite loop when API return 500
    const eventSource = createEventSource(channels, topics)
    eventSource.onmessage = (({ data }) => {
      callback({ data: JSON.parse(data) })
    })

    return eventSource
  }, [createEventSource, mercure])

  return {
    subscribe,
  }
}

export function useMercureData<TData extends Item | PagedCollection<Item> | null | undefined>(
  channels: string[],
  deps: TData,
): TData {
  const { subscribe } = useMercure()
  const [data, setData] = React.useState<TData>(deps)

  // This useEffect is used to update the data when the deps change even if it's a prop
  React.useEffect(() => {
    setData(deps)
  }, [deps])

  React.useEffect(() => {
    if (!data) {
      return () => {}
    }

    if (!isItem<Item>(data) && !isPagedCollection<Item>(data)) {
      throw new Error('Object sent is not in JSON-LD format.')
    }

    // It's a collection with multiple items
    if (isPagedCollection<Item>(data)) {
      if (data['hydra:member'] && data['hydra:member'].length === 0) {
        return () => {}
      }

      const itemUris: string[] = (data['hydra:member'] || []).map(item => item['@id']!)
      const eventSource = subscribe(channels, itemUris, ({ data: datum }: {data: any}) => {
        if (isItem(datum) && data['hydra:member']) {
          const pos = data['hydra:member'].findIndex(item => item['@id'] === datum['@id']!)
          if (pos !== -1) {
            data['hydra:member'][pos] = datum
            setData({ ...data })
          }
        }
      })

      return () => eventSource?.close()
    }

    // It's a single object
    if (isItem<Item>(data)) {
      const itemUri = data['@id']
      if (itemUri) {
        const eventSource = subscribe(channels, [itemUri], setData)

        return () => eventSource?.close()
      }
    }

    return () => {}
  }, [data, channels, subscribe])

  return data
}
