import React from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'context/I18nProvider'

const blockDefaultHeight = '50px'
const blockContentHeight = '6'

function ModulesAvailable({ number, onClick, isFullHeight, blockNb }) {
  const { i18n } = useI18n()
  const height = isFullHeight ? `${(blockNb) * blockContentHeight - 2.5}em` : blockDefaultHeight
  const zIndex = isFullHeight ? 2 : 0
  return (
    <div
      className="block-content"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
      style={{ height, zIndex }}
    >
      <span className="number">{number}</span>
      <span className="text">{i18n('available_modules')}</span>
    </div>
  )
}

ModulesAvailable.propTypes = {
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isFullHeight: PropTypes.bool,
  blockNb: PropTypes.number,
}

export default ModulesAvailable
