import React from 'react'
import PropTypes from 'prop-types'
import { EditOutlined } from '@ant-design/icons'
import { useProfile } from 'context/ProfileProvider'

function EditIcon({ onClick, yPosition }) {
  const { hasPermission } = useProfile()
  const verticalPositioning = (typeof yPosition === 'number') ? { top: `${yPosition * 6}em` } : {}
  return hasPermission('MATRIX_CAN_EDIT_MATRIX') && (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="edit-icon" onClick={onClick} style={verticalPositioning}>
      <EditOutlined />
    </div>
  )
}

EditIcon.propTypes = {
  onClick: PropTypes.func,
  yPosition: PropTypes.number,
}

export default EditIcon
