import { redirect } from 'react-router-dom'
import { API_SESSION_ORDERS } from 'constants/api-v2'

export const createOrder = client => (
  async ({ params, request }) => {
    const { id, lang } = params

    const formData = await request.formData()
    const data = Object.fromEntries(formData)

    const payload = {
      session: `/api/2.0/sessions/${id}`,
      ...data,
      lines: JSON.parse(data.lines).map(line => ({
        ...line,
        price: parseFloat(line.price),
        quantity: parseFloat(line.quantity),
        vat: parseFloat(line.vat),
      })),
    }

    try {
      if ('id' in data) {
        payload.id = parseInt(data.id, 10)
        await client.patch(`${API_SESSION_ORDERS}/${data.id}`, payload)
      } else {
        await client.post(API_SESSION_ORDERS, payload)
      }
    } catch (e) {
      return { error: e.response }
    }

    return redirect(`/${lang}/sessions/${id}/orders`)
  }
)
