import * as React from 'react'
import PropTypes from 'prop-types'
import QueryClient from 'services/query'

const QueryContext = React.createContext()

export default function QueryContextProvider({ client, children }) {
  const value = React.useMemo(() => ({
    client,
  }), [client])

  return (
    <QueryContext.Provider value={value}>
      {children}
    </QueryContext.Provider>
  )
}

QueryContextProvider.propTypes = {
  client: PropTypes.instanceOf(QueryClient),
  children: PropTypes.node,
}

export function useQuery() {
  const context = React.useContext(QueryContext)
  if (context === undefined) {
    throw new Error('QueryContext must be used within a Provider')
  }
  return context
}
