import * as React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { SearchOutlined, WarningOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import PageWrapper from 'components/PageWrapper'
import Section from 'components/UI/Section'

interface ErrorType {
  message?: string
  statusText?: string
}

interface BaseProps {
  title?: string
  className?: string
  customIcon?: any
  customContent?: string
}

interface ErrorContentProps {
  error: ErrorType | any
}

interface ErrorPageProps {
  customError: object
}

export function ErrorContent({ error, title, className, customIcon, customContent }: BaseProps & ErrorContentProps) {
  const { i18n } = useI18n()
  const props = className ? { className } : {}
  const Icon = customIcon || WarningOutlined

  let customErrorMessage = ''

  if (error?.response?.status === 403) {
    customErrorMessage = i18n('page-403.text')
  }

  return (
    <div id="error-page" {...props}>
      <div className="flex items-center mb-8">
        <Icon className="text-orange-500 font-bold text-3xl mr-4 flex items-center" />
        <h2 className="text-3xl font-bold my-0 border-l-4 border-orange-500">
          {title || i18n('global.error_message')}
        </h2>
      </div>
      <div>
        <i>{customErrorMessage || i18n(error.statusText) || error.message}</i>
      </div>
      {customContent}
    </div>
  )
}

export default function ErrorPage(
  { customError, customIcon, customContent, title, className }: BaseProps & ErrorPageProps,
) {
  // @see https://github.com/remix-run/react-router/discussions/9628
  const routeError: ErrorType | unknown = useRouteError()
  const { i18n } = useI18n()

  let error = routeError || customError
  let icon = customIcon
  let customTitle = title

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      icon = SearchOutlined
      error = { statusText: i18n('page-not-found.text') }
      customTitle = '404'
    }
  }

  return (
    <Section className={className || ''}>
      <PageWrapper>
        <main className="w-full h-full">
          <div className="w-full h-full p-12">
            <ErrorContent
              error={error}
              title={customTitle}
              customIcon={icon}
              customContent={customContent} />
          </div>
        </main>
      </PageWrapper>
    </Section>
  )
}
