export const getFilenameFromContentDisposition = disposition => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(disposition)

  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '')
  }

  return undefined
}
