import QueryClient from 'services/query'
import { API_ADMIN_CERTIFICATES } from 'constants/api-v2'

interface LoaderParams {
  params: {
    idCertificate: string
    idAccreditation: string
  }
}

export const loadAdminCertificate = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { idCertificate = '' } = params

    let certificate

    try {
      certificate = await client.get(`${API_ADMIN_CERTIFICATES}/${idCertificate}`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }

    return { certificate }
  }
)
