import { API_URL_LOGIN as URL } from 'constants/api'

export const login = client => (
  async ({ request }) => {
    const formData = await request.formData()
    const payload = Object.fromEntries(formData)

    let resp = null
    let token = null
    let error = null

    try {
      resp = await client.post(URL, payload)
      token = resp.token
    } catch (e) {
      error = e
    }

    return { error, token }
  }
)
