import * as React from 'react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

interface CollapsableMenuItemProps {
  open: boolean
  onClick: Function
  label: string
  icon: React.ReactElement
}

export default function CollapsableMenuItem({ open, onClick, label, icon }: CollapsableMenuItemProps) {
  const MenuIcon = open ? CaretUpOutlined : CaretDownOutlined
  const Icon = () => React.cloneElement(icon, {
    className: 'text-[--secondaryLight] mr-2 w-6 text-[1.2rem]',
  })

  return (
    <button
      type="button"
      className={`
        border-none leading-6 pl-3 py-3 my-0.5 relative flex items-center rounded bg-transparent hover:bg-gray-200
        font-bold cursor-pointer w-full
      `}
      onClick={() => onClick()}
    >
      <Icon />
      <div className="flex w-full text-left">
        <div>
          {label}
        </div>
        <div className="grow" />
        <MenuIcon className="mr-1.5 text-[--secondaryLight]" />
      </div>
    </button>
  )
}
