import React from 'react'
import Icon from '@ant-design/icons'

const GraduationCapSvg = () => (
  <svg width="1.2em" height="1.1em" fill="currentColor" viewBox="0 0 24 24">
    <path
      d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3 1 9l11 6 9-4.91V17h2V9L12 3z"
      style={{ fill: 'currentcolor' }}
    />
  </svg>
)

const TrainingIcon = props => <Icon component={GraduationCapSvg} {...props} />

export default TrainingIcon
