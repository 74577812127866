import * as React from 'react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

export const useCollapse = () => {
  const [open, setOpen] = React.useState(false)

  const OpenIcon = open ? CaretUpOutlined : CaretDownOutlined

  const Icon = props => (
    <OpenIcon
      onClick={() => setOpen(!open)}
      {...props}
    />
  )

  return {
    open,
    setOpen,
    Icon,
  }
}
