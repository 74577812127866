import React from 'react'
import { Modal as AntdModal } from 'antd'
import type { ModalProps } from 'antd'

export default function Modal({ children, ...props }: ModalProps) {
  return (
    <AntdModal open {...props}>
      {children}
    </AntdModal>
  )
}
