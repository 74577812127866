import * as React from 'react'
import type { Permission } from 'shared/types'
import { useProfile } from 'context/ProfileProvider'
import Page403 from 'components/Page403'
import Spinner from 'components/Spinner'

interface PrivateRouteProps {
  permissions?: Permission[]
  profile?: string
  children: typeof React.Children
}

function PrivateRoute({ permissions = [], profile, children }: PrivateRouteProps) {
  const { profile: currentProfile, hasPermission } = useProfile()

  function hasPermissions() {
    if (!permissions.length) return true
    return permissions.some(permission => hasPermission(permission))
  }

  // Permissions are not fetched yet
  if (!currentProfile?.permissions) return <Spinner />

  // Permissions denied: 403
  if (!hasPermissions()) return <Page403 />

  // Profile denied: 403
  if (profile && currentProfile.type !== profile) return <Page403 />

  // Permissions OK and profile OK
  return children
}

export default PrivateRoute
