// Credits: https://javascript.info/blob
export const triggerDownloadBlob = (data, mimeType, filename, target = undefined) => {
  const link = document.createElement('a')
  const blob = new Blob([data], { type: mimeType })

  if (filename) {
    link.download = filename
  }

  if (!!target) {
    link.target = target
  }
  link.href = URL.createObjectURL(blob)
  link.click()

  URL.revokeObjectURL(link.href)
}
