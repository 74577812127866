import React, { useState } from 'react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import SubMenu from './SubMenu'
import type { MenuItem } from '../types'

interface AdminMenuProps {
  items: MenuItem[]
}

export default function AdminMenu({ items, ...props }: AdminMenuProps) {
  const { i18n } = useI18n()
  const [open, setOpen] = useState(false)

  const Icon = open ? CaretUpOutlined : CaretDownOutlined

  const toggleOpen = () => setOpen(() => !open)

  return (
    <ul className="p-0 m-0" {...props}>
      <button
        type="button"
        className={`
          border-none leading-4 pl-3 py-4 mb-0.5 relative flex items-center rounded
          bg-transparent hover:bg-gray-200
          font-bold cursor-pointer w-full
        `}
        onClick={toggleOpen}
      >
        <div className="text-black">
          {i18n('menu.admin')}
        </div>
        <div className="grow" />
        <Icon className="chevron toggle-all mr-1.5 text-[--secondaryLight]" />
      </button>
      <ul className="p-0 m-0">
        {
          items.map(item => (
            <SubMenu item={item} key={item.name} adminOpen={open} />
          ))
        }
      </ul>
    </ul>
  )
}
