/* eslint-disable react/prop-types */
import * as React from 'react'
import { useSidebar } from 'context/SidebarProvider'

export default function PageWrapper({ children }) {
  const { collapsed, filterOpen, navOpen } = useSidebar() || { collapsed: false, filterOpen: false, navOpen: false }

  let padding
  if (collapsed) {
    padding = 'pl-[4px]'
  } else {
    if (navOpen) padding = 'pl-[256px]'
    if (filterOpen) padding = 'pl-[280px]'
  }

  return (
    <div
      id="page-wrapper"
      className={`
        flex grow flex-col justify-start w-full transition-all duration-500 ease ${padding}
      `}
    >
      {children}
    </div>
  )
}
